import { IconAngleLeft } from "components/Icons";
import styled from "styled-components";
import { device, typography } from "constant/styles";

type Props = JSX.IntrinsicElements["button"];

const Button = styled.button`
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: 16px;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 9px 12px;
    background-color: #fff;

    @media ${device.phone} {
        padding: 6px 8px;
    }

    svg {
        margin-right: 4px;
        path {
            fill: #29247a;
        }
    }

    span {
        color: #29247a;
        font-family: ${typography.fontFamily};
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        
        @media ${device.phone} {
            font-size: 16px;
        }
    }
`;

const BtnBack = ({ children, className, ...props }: Props) => {
    return (
        <Button type="button" className={className} {...props}>
            <IconAngleLeft />

            <span>{children}</span>
        </Button>
    );
};

export default BtnBack;
