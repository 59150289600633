import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Aircraft = {
  __typename: 'Aircraft';
  aircraftType: AircraftType;
  flightDurationInMinutes: Scalars['Int'];
  flightMinPrice?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  jets?: Maybe<Scalars['String']>;
  maxRatePerHour: Scalars['String'];
  minRatePerHour: Scalars['String'];
  name: Scalars['String'];
  priceRangeDifference: Scalars['String'];
  seats: Scalars['Int'];
};

export type AircraftCategoriesParams = {
  passengers: Passengers;
  trips: Array<TripInput>;
};

export type AircraftCategoriesResponse = {
  __typename: 'AircraftCategoriesResponse';
  aircrafts: Array<Aircraft>;
};

export enum AircraftType {
  HeavyJet = 'HEAVY_JET',
  LightJet = 'LIGHT_JET',
  MidSizeJet = 'MID_SIZE_JET',
  SuperMidJet = 'SUPER_MID_JET',
  TurboProp = 'TURBO_PROP',
  UltraLongRangeJet = 'ULTRA_LONG_RANGE_JET'
}

export type Airport = {
  __typename: 'Airport';
  bestPrice: Scalars['Boolean'];
  cityName?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  destinationFromUserInKm?: Maybe<Scalars['String']>;
  flightDurationInMinutes?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
};

export type AirportsResponse = {
  __typename: 'AirportsResponse';
  airports: Array<Airport>;
};

export type ConfigsResponse = {
  __typename: 'ConfigsResponse';
  priceMessage: Scalars['String'];
};

export enum CurrencyEnum {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Fok = 'FOK',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ggp = 'GGP',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Imp = 'IMP',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jep = 'JEP',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kid = 'KID',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sle = 'SLE',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Tvd = 'TVD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

export type DateTime = {
  date: Scalars['Date'];
  time?: InputMaybe<Time>;
};

export type DateTimeValue = {
  departDateTime: DateTime;
  returnDateTime?: InputMaybe<DateTime>;
};

export type GeoipResponse = {
  __typename: 'GeoipResponse';
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyEnum>;
};

export type InquireInput = {
  aircraftId?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  isFuelStopOk: Scalars['Boolean'];
  lastName: Scalars['String'];
  luggage?: InputMaybe<Luggage>;
  passengers: Passengers;
  pets?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  preferredContactType: PreferredContactType;
  trips: Array<TripInput>;
  utmAdContent?: InputMaybe<Scalars['String']>;
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
};

export type InquireResponse = {
  __typename: 'InquireResponse';
  inquireCrmId?: Maybe<Scalars['Int']>;
  inquireId?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

export type InquireUpdateInput = {
  aircraftId: Scalars['Int'];
  inquireId: Scalars['Int'];
};

export enum LanguageEnum {
  En = 'EN',
  Es = 'ES',
  Fr = 'FR'
}

export type Luggage = {
  carryOn?: InputMaybe<Scalars['Int']>;
  golfBags?: InputMaybe<Scalars['Int']>;
  handLuggage?: InputMaybe<Scalars['Int']>;
  other?: InputMaybe<Scalars['String']>;
  skis?: InputMaybe<Scalars['Int']>;
};

export type Mutation = {
  __typename: 'Mutation';
  inquire: InquireResponse;
  inquireUpdate: InquireResponse;
};


export type MutationInquireArgs = {
  inquireInput: InquireInput;
};


export type MutationInquireUpdateArgs = {
  inquireInput: InquireUpdateInput;
};

export type Passengers = {
  adults?: Scalars['Int'];
  children?: Scalars['Int'];
  infants?: Scalars['Int'];
};

export enum PreferredContactType {
  Call = 'CALL',
  Email = 'EMAIL',
  Text = 'TEXT',
  Whatsapp = 'WHATSAPP'
}

export type Query = {
  __typename: 'Query';
  aircraftCategories: AircraftCategoriesResponse;
  airports: AirportsResponse;
  configs: ConfigsResponse;
  currencies: Array<CurrencyEnum>;
  geoip: GeoipResponse;
  ratePerHour: RatePerHourResponse;
  startLocation: StartLocationResponse;
};


export type QueryAircraftCategoriesArgs = {
  currency?: CurrencyEnum;
  params: AircraftCategoriesParams;
};


export type QueryAirportsArgs = {
  q: Scalars['String'];
};


export type QueryConfigsArgs = {
  currency?: CurrencyEnum;
  language?: LanguageEnum;
};

export type RatePerHourResponse = {
  __typename: 'RatePerHourResponse';
  max: Scalars['String'];
  min: Scalars['String'];
};

export type Route = {
  airportFrom: Scalars['Int'];
  airportTo: Scalars['Int'];
};

export type StartLocationResponse = {
  __typename: 'StartLocationResponse';
  nearLocations: Array<Airport>;
  userLocation: Airport;
};

export type Time = {
  hour?: InputMaybe<Scalars['Int']>;
  minute?: InputMaybe<Scalars['Int']>;
};

export type TripInput = {
  dateTimeValue: DateTimeValue;
  route: Route;
};

export type AirportFragment = { __typename: 'Airport', id: number, countryName?: string | null, cityName?: string | null, countryCode?: string | null, code: string, bestPrice: boolean, lat: number, lng: number, name?: string | null, destinationFromUserInKm?: string | null };

export type InquireMutationVariables = Exact<{
  inquireInput: InquireInput;
}>;


export type InquireMutation = { __typename: 'Mutation', inquire: { __typename: 'InquireResponse', ok: boolean, inquireId?: number | null } };

export type InquireUpdateMutationVariables = Exact<{
  inquireInput: InquireUpdateInput;
}>;


export type InquireUpdateMutation = { __typename: 'Mutation', inquireUpdate: { __typename: 'InquireResponse', ok: boolean, inquireCrmId?: number | null, inquireId?: number | null } };

export type AircraftFragment = { __typename: 'Aircraft', id: number, name: string, aircraftType: AircraftType, seats: number, flightMinPrice?: string | null, flightDurationInMinutes: number, priceRangeDifference: string, jets?: string | null };

export type AircraftCategoriesQueryVariables = Exact<{
  currency: CurrencyEnum;
  params: AircraftCategoriesParams;
}>;


export type AircraftCategoriesQuery = { __typename: 'Query', aircraftCategories: { __typename: 'AircraftCategoriesResponse', aircrafts: Array<{ __typename: 'Aircraft', id: number, name: string, aircraftType: AircraftType, seats: number, flightMinPrice?: string | null, flightDurationInMinutes: number, priceRangeDifference: string, jets?: string | null }> } };

export type AirportsQueryVariables = Exact<{
  q: Scalars['String'];
}>;


export type AirportsQuery = { __typename: 'Query', airports: { __typename: 'AirportsResponse', airports: Array<{ __typename: 'Airport', id: number, countryName?: string | null, cityName?: string | null, countryCode?: string | null, code: string, bestPrice: boolean, lat: number, lng: number, name?: string | null, destinationFromUserInKm?: string | null }> } };

export type ConfigsQueryVariables = Exact<{
  currency?: CurrencyEnum;
  language?: LanguageEnum;
}>;


export type ConfigsQuery = { __typename: 'Query', configs: { __typename: 'ConfigsResponse', priceMessage: string } };

export type CurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrenciesQuery = { __typename: 'Query', currencies: Array<CurrencyEnum> };

export type GeoipQueryVariables = Exact<{ [key: string]: never; }>;


export type GeoipQuery = { __typename: 'Query', geoip: { __typename: 'GeoipResponse', country?: string | null, currency?: CurrencyEnum | null } };

export const AirportFragmentDoc = gql`
    fragment Airport on Airport {
  id
  countryName
  cityName
  countryCode
  code
  bestPrice
  lat
  lng
  name
  destinationFromUserInKm
  bestPrice
}
    `;
export const AircraftFragmentDoc = gql`
    fragment Aircraft on Aircraft {
  id
  name
  aircraftType
  seats
  flightMinPrice
  flightDurationInMinutes
  priceRangeDifference
  jets
}
    `;
export const InquireDocument = gql`
    mutation Inquire($inquireInput: InquireInput!) {
  inquire(inquireInput: $inquireInput) {
    ok
    inquireId
  }
}
    `;
export type InquireMutationFn = Apollo.MutationFunction<InquireMutation, InquireMutationVariables>;

/**
 * __useInquireMutation__
 *
 * To run a mutation, you first call `useInquireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquireMutation, { data, loading, error }] = useInquireMutation({
 *   variables: {
 *      inquireInput: // value for 'inquireInput'
 *   },
 * });
 */
export function useInquireMutation(baseOptions?: Apollo.MutationHookOptions<InquireMutation, InquireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InquireMutation, InquireMutationVariables>(InquireDocument, options);
      }
export type InquireMutationHookResult = ReturnType<typeof useInquireMutation>;
export type InquireMutationResult = Apollo.MutationResult<InquireMutation>;
export type InquireMutationOptions = Apollo.BaseMutationOptions<InquireMutation, InquireMutationVariables>;
export const InquireUpdateDocument = gql`
    mutation InquireUpdate($inquireInput: InquireUpdateInput!) {
  inquireUpdate(inquireInput: $inquireInput) {
    ok
    inquireCrmId
    inquireId
  }
}
    `;
export type InquireUpdateMutationFn = Apollo.MutationFunction<InquireUpdateMutation, InquireUpdateMutationVariables>;

/**
 * __useInquireUpdateMutation__
 *
 * To run a mutation, you first call `useInquireUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquireUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquireUpdateMutation, { data, loading, error }] = useInquireUpdateMutation({
 *   variables: {
 *      inquireInput: // value for 'inquireInput'
 *   },
 * });
 */
export function useInquireUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InquireUpdateMutation, InquireUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InquireUpdateMutation, InquireUpdateMutationVariables>(InquireUpdateDocument, options);
      }
export type InquireUpdateMutationHookResult = ReturnType<typeof useInquireUpdateMutation>;
export type InquireUpdateMutationResult = Apollo.MutationResult<InquireUpdateMutation>;
export type InquireUpdateMutationOptions = Apollo.BaseMutationOptions<InquireUpdateMutation, InquireUpdateMutationVariables>;
export const AircraftCategoriesDocument = gql`
    query AircraftCategories($currency: CurrencyEnum!, $params: AircraftCategoriesParams!) {
  aircraftCategories(currency: $currency, params: $params) {
    aircrafts {
      ...Aircraft
    }
  }
}
    ${AircraftFragmentDoc}`;

/**
 * __useAircraftCategoriesQuery__
 *
 * To run a query within a React component, call `useAircraftCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAircraftCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAircraftCategoriesQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAircraftCategoriesQuery(baseOptions: Apollo.QueryHookOptions<AircraftCategoriesQuery, AircraftCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AircraftCategoriesQuery, AircraftCategoriesQueryVariables>(AircraftCategoriesDocument, options);
      }
export function useAircraftCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AircraftCategoriesQuery, AircraftCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AircraftCategoriesQuery, AircraftCategoriesQueryVariables>(AircraftCategoriesDocument, options);
        }
export type AircraftCategoriesQueryHookResult = ReturnType<typeof useAircraftCategoriesQuery>;
export type AircraftCategoriesLazyQueryHookResult = ReturnType<typeof useAircraftCategoriesLazyQuery>;
export type AircraftCategoriesQueryResult = Apollo.QueryResult<AircraftCategoriesQuery, AircraftCategoriesQueryVariables>;
export const AirportsDocument = gql`
    query Airports($q: String!) {
  airports(q: $q) {
    airports {
      ...Airport
    }
  }
}
    ${AirportFragmentDoc}`;

/**
 * __useAirportsQuery__
 *
 * To run a query within a React component, call `useAirportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAirportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAirportsQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useAirportsQuery(baseOptions: Apollo.QueryHookOptions<AirportsQuery, AirportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AirportsQuery, AirportsQueryVariables>(AirportsDocument, options);
      }
export function useAirportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AirportsQuery, AirportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AirportsQuery, AirportsQueryVariables>(AirportsDocument, options);
        }
export type AirportsQueryHookResult = ReturnType<typeof useAirportsQuery>;
export type AirportsLazyQueryHookResult = ReturnType<typeof useAirportsLazyQuery>;
export type AirportsQueryResult = Apollo.QueryResult<AirportsQuery, AirportsQueryVariables>;
export const ConfigsDocument = gql`
    query Configs($currency: CurrencyEnum! = USD, $language: LanguageEnum! = EN) {
  configs(currency: $currency, language: $language) {
    priceMessage
  }
}
    `;

/**
 * __useConfigsQuery__
 *
 * To run a query within a React component, call `useConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigsQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useConfigsQuery(baseOptions?: Apollo.QueryHookOptions<ConfigsQuery, ConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfigsQuery, ConfigsQueryVariables>(ConfigsDocument, options);
      }
export function useConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfigsQuery, ConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfigsQuery, ConfigsQueryVariables>(ConfigsDocument, options);
        }
export type ConfigsQueryHookResult = ReturnType<typeof useConfigsQuery>;
export type ConfigsLazyQueryHookResult = ReturnType<typeof useConfigsLazyQuery>;
export type ConfigsQueryResult = Apollo.QueryResult<ConfigsQuery, ConfigsQueryVariables>;
export const CurrenciesDocument = gql`
    query Currencies {
  currencies
}
    `;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
      }
export function useCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
        }
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesQueryResult = Apollo.QueryResult<CurrenciesQuery, CurrenciesQueryVariables>;
export const GeoipDocument = gql`
    query Geoip {
  geoip {
    country
    currency
  }
}
    `;

/**
 * __useGeoipQuery__
 *
 * To run a query within a React component, call `useGeoipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeoipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeoipQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeoipQuery(baseOptions?: Apollo.QueryHookOptions<GeoipQuery, GeoipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeoipQuery, GeoipQueryVariables>(GeoipDocument, options);
      }
export function useGeoipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeoipQuery, GeoipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeoipQuery, GeoipQueryVariables>(GeoipDocument, options);
        }
export type GeoipQueryHookResult = ReturnType<typeof useGeoipQuery>;
export type GeoipLazyQueryHookResult = ReturnType<typeof useGeoipLazyQuery>;
export type GeoipQueryResult = Apollo.QueryResult<GeoipQuery, GeoipQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    