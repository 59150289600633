import classNames from "classnames";
import DateTimeRangePicker from "components/DateTimeRangePicker";
import DestinationFields from "components/DestinationFields";
import {IconClose} from "components/Icons";
import PassengersField from "components/PassengersField";
import TripTypeField from "components/TripTypeField";
import {Container} from "components/UI";
import {BREAKPOINT_DESKTOP, BREAKPOINT_IPAD} from "constant";
import { LanguageEnum, useConfigsQuery} from "gql/generted";
import {createTripConfig, useWidthCondition} from "helpers";
import update from "immutability-helper";
import {FormEvent, Fragment, useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {TripType, UseSearchConfig} from "types";
import {
  Component,
  Form,
  MultiLegActions,
  MultiLegAddBtn,
  MultiLegContainer,
  MultiLegFields,
  MultiLegRemoveBtn,
  MultiLegRow,
  OneWayContainer,
  PriceText,
  RoundTripContainer,
  SubmitBtn,
} from "./components";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const getSiteHeader = () => {
  return document.querySelector<HTMLDivElement>(
    "[data-elementor-type=header]"
  )!;
};

const MAX_TRIPS = 5;

type Props = UseSearchConfig & {
  onClickSubmit(): void;
  show?: boolean;
  fixed?: boolean;
  scrollToRealForm?: () => void;
  setTarget?: (ref: HTMLElement | null) => void;
};

const BaseSearchRow = ({
                         trips,
                         setTrips,
                         onClickSubmit,
                         passengers,
                         setPassengers,
                         tripType,
                         setTripType: _setTripType,
                         currency,
                         setCurrency,
                         fixed,
                         show,
                         setTarget,
                         scrollToRealForm,
                       }: Props) => {
  const configQuery = useConfigsQuery({
    skip: !currency,
    variables: {
      currency: currency!,
      language: i18next.language.toUpperCase() as LanguageEnum || LanguageEnum.En},
  });
  const configs = configQuery.data?.configs;
  const isIpadOrLess = useWidthCondition((w) => w < BREAKPOINT_DESKTOP);
  const isPhone = useWidthCondition((w) => w < BREAKPOINT_IPAD);
  const [topOffset, setTopOffset] = useState(0);

  const canAddMoreTrips = trips.length < MAX_TRIPS;

  const disabledSubmitBtn = trips.some((trip) => {
    return (
      !trip.dateTimeValue.departDateTime.date ||
      !trip.destinationValue.from ||
      !trip.destinationValue.to
    );
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    onClickSubmit();
  };

  const onChangeDestination = (
    newValue: UseSearchConfig["trips"][number]["destinationValue"],
    index: number
  ) => {
    setTrips(
      update(trips, {
        [index]: {
          destinationValue: () => newValue,
        },
      })
    );
  };

  const onChangeDateTime = (
    newValue: UseSearchConfig["trips"][number]["dateTimeValue"],
    index: number
  ) => {
    setTrips(
      update(trips, {
        [index]: {
          dateTimeValue: () => newValue,
        },
      })
    );
  };

  const setTripType = (newTripType: TripType) => {
    if (tripType !== newTripType) {
      switch (tripType) {
        case TripType.MULTI_LEG:
          setTrips(trips.slice(0, 1));
          break;
      }
      switch (newTripType) {
        case TripType.MULTI_LEG:
          addNewTrip();
          break;
      }
    }

    _setTripType(newTripType);
  };

  const addNewTrip = () => {
    setTrips([...trips, createTripConfig()]);
  };

  const removeTrip = (index: number) => {
    setTrips(trips.filter((_, i) => i !== index));

    if (trips.length === 2) {
      setTripType(TripType.ONE_WAY);
    }
  };
  const [t] = useTranslation('common')
  useEffect(() => {
    if (fixed && show) {
      const header = getSiteHeader();
      const isIpadOrLess = window.innerWidth < BREAKPOINT_IPAD;
      const newOffset =
        (isIpadOrLess
            ? header
            : header.querySelectorAll<HTMLDivElement>(
              ".elementor-background-overlay"
            )[0]
        )?.offsetHeight || 0;

      setTopOffset(newOffset);
    } else {
      // setTopOffset(0);
    }
  }, [fixed, show]);

  const PT = !fixed && <PriceText>{configs?.priceMessage}</PriceText>;

  const FormSubmit = (
    <SubmitBtn
      disabled={disabledSubmitBtn}
      type="submit"
      className={"SubmitBtn"}
    >
      {t('getQuote')}
    </SubmitBtn>
  );

  const AddLegBtn = canAddMoreTrips && (
    <MultiLegAddBtn type="button" onClick={addNewTrip}>
      + {t('addAnotherFlight')}
    </MultiLegAddBtn>
  );

  const Trip = (
    <TripTypeField
      value={t(tripType)}
      onChange={setTripType}
      className="TripTypeField"
    />
  );
  const Passengers = (
    <PassengersField
      className="PassengersField"
      value={passengers}
      short={isIpadOrLess && !isPhone && tripType === TripType.MULTI_LEG}
      onChange={setPassengers}
    />
  );

  if (fixed && isPhone) {
    return (
      <StickyMobileSearchRow
        show={show}
        topOffset={topOffset}
        scrollToRealForm={scrollToRealForm}
      />
    );
  }

  return (
    <Component
      ref={setTarget}
      className={classNames({
        fixed: fixed,
        show,
      })}
      style={{top: topOffset}}
    >
      <Container>
        <Form
          className={classNames({
            fixed: fixed,
          })}
          onSubmit={onSubmit}
        >
          {isPhone && PT}

          {tripType === TripType.ONE_WAY && (
            <OneWayContainer>
              {Trip}
              <DestinationFields
                className={"DestinationFields"}
                aiportFieldFromClassName={"AirportFieldFrom"}
                aiportFieldToClassName={"AirportFieldTo"}
                value={trips[0].destinationValue}
                onChange={(v) => onChangeDestination(v, 0)}
              />
              <DateTimeRangePicker
                departFieldClassName={"DepartField"}
                departOnly
                className={"DateTimeRangePicker"}
                value={trips[0].dateTimeValue}
                onChange={(v) => onChangeDateTime(v, 0)}
              />

              {Passengers}
              {FormSubmit}
            </OneWayContainer>
          )}
          {tripType === TripType.ROUND_TRIP && (
            <RoundTripContainer>
              {Trip}
              <DestinationFields
                className={"DestinationFields"}
                aiportFieldFromClassName={"AirportFieldFrom"}
                aiportFieldToClassName={"AirportFieldTo"}
                value={trips[0].destinationValue}
                onChange={(v) => onChangeDestination(v, 0)}
              />
              <DateTimeRangePicker
                departFieldClassName={"DepartField"}
                returnFieldClassName={"ReturnField"}
                departOnly={false}
                className={"DateTimeRangePicker"}
                value={trips[0].dateTimeValue}
                onChange={(v) => onChangeDateTime(v, 0)}
              />

              {Passengers}
              {FormSubmit}
            </RoundTripContainer>
          )}
          {tripType === TripType.MULTI_LEG && (
            <MultiLegContainer>
              {trips.map((trip, index) => {
                return (
                  <MultiLegRow key={index}>
                    {!isPhone &&
                      (index === 0 ? (
                        Trip
                      ) : index === 1 ? (
                        Passengers
                      ) : (
                        <div/>
                      ))}

                    <MultiLegFields>
                      <DestinationFields
                        className={"DestinationFields"}
                        aiportFieldFromClassName={
                          "AirportFieldFrom"
                        }
                        aiportFieldToClassName={
                          "AirportFieldTo"
                        }
                        value={trip.destinationValue}
                        onChange={(v) =>
                          onChangeDestination(
                            v,
                            index
                          )
                        }
                      />

                      <DateTimeRangePicker
                        departOnly
                        departFieldClassName={
                          "DepartField"
                        }
                        className={
                          "DateTimeRangePicker"
                        }
                        minDateForSelect={
                          trips[index - 1]
                            ?.dateTimeValue
                            .departDateTime.date
                        }
                        value={trip.dateTimeValue}
                        onChange={(v) =>
                          onChangeDateTime(v, index)
                        }
                      />

                      {index !== 0 && (
                        <MultiLegRemoveBtn
                          onClick={(e) => {
                            e.stopPropagation();
                            removeTrip(index);
                          }}
                          type="button"
                        >
                          <IconClose/>
                        </MultiLegRemoveBtn>
                      )}
                    </MultiLegFields>

                    {!isIpadOrLess &&
                      (index === 0
                        ? FormSubmit
                        : index === trips.length - 1
                          ? AddLegBtn
                          : undefined)}
                  </MultiLegRow>
                );
              })}

              {isIpadOrLess && (
                <MultiLegActions>
                  {isPhone && Passengers}
                  {AddLegBtn}
                  {FormSubmit}
                </MultiLegActions>
              )}
            </MultiLegContainer>
          )}

          {!isPhone && PT}

                    {/* {!fixed && currency && (
                        <CurrencySelect
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value as any)}
                        >
                            {Object.values(CurrencyEnum).map((currency) => (
                                <option key={currency}>{currency}</option>
                            ))}
                        </CurrencySelect>
                    )} */}
                </Form>
            </Container>
        </Component>
    );
};

const StickyMobileSearchRow = ({
                                 topOffset,
                                 scrollToRealForm,
                                 show,
                               }: {
  show?: boolean;
  topOffset: number;
  scrollToRealForm?: () => void;
}) => {
  const {t} = useTranslation('common')
  return (
    <Component
      className={classNames("fixed", show && "show")}
      style={{top: topOffset}}
    >
      <Form className={classNames("fixed")}>
        <SubmitBtn
          type="button"
          onClick={scrollToRealForm}
          className={classNames("fluid")}
        >
          {t("findPrivateJet")}
        </SubmitBtn>
      </Form>
    </Component>
  );
};

const SearchRow = ({
                     trips,
                     setTrips,
                     onClickSubmit,
                     passengers,
                     setPassengers,
                     tripType,
                     setTripType,
                     currency,
                     setCurrency,
                   }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!target) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
      }
    );

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [target]);

  const scrollToRealForm = () => {
    target?.scrollIntoView({behavior: "smooth"});
  };

  return (
    <Fragment>
      {createPortal(
        <div id="israjets-embedded-widget">
          <BaseSearchRow
            fixed
            scrollToRealForm={scrollToRealForm}
            show={!isVisible}
            trips={trips}
            setTrips={setTrips}
            onClickSubmit={onClickSubmit}
            passengers={passengers}
            setPassengers={setPassengers}
            tripType={tripType}
            setTripType={setTripType}
            currency={currency}
            setCurrency={setCurrency}
          />
        </div>,
        getSiteHeader()
      )}
      <BaseSearchRow
        setTarget={setTarget}
        trips={trips}
        setTrips={setTrips}
        onClickSubmit={onClickSubmit}
        passengers={passengers}
        setPassengers={setPassengers}
        tripType={tripType}
        setTripType={setTripType}
        currency={currency}
        setCurrency={setCurrency}
      />
    </Fragment>
  );
};

export default SearchRow;
