import classnames from "classnames";
import {IconUser} from "components/Icons";
import {BREAKPOINT_DESKTOP, BREAKPOINT_IPAD} from "constant";
import {colors, device, typography} from "constant/styles";
import {Passengers} from "gql/generted";
import {useWidthCondition} from "helpers";
import update from "immutability-helper";
import {useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

type Props = {
  value: Required<Passengers>;
  readOnly?: boolean;
  onChange?: (v: Required<Passengers>) => void;
  className?: string;
  short?: boolean;
};

const Component = styled.div`
    border: 1px solid transparent;
    background: ${colors.white.primary};
    height: 60px;
    border-left-color: ${colors.grey.secondary};
    transition: 0.2s border-color;
    position: relative;

    @media ${device.phone} {
        border-left-color: transparent;
        border-bottom-color: ${colors.grey.secondary};
    }

    &.focused {
        border-color: ${colors.black.primary};
    }
`;

const Controller = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 16px 8px;
    align-items: center;
`;

const ControllerLabel = styled.span`
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: ${typography.fontFamily};
    transition: 0.2s;
    color: ${colors.grey.dark};
    top: 50%;
    transform: translateY(-50%);
    left: 8px;

    &.hasValue {
        top: -8px;
        color: ${colors.white.primary};
        transform: translateY(-100%);

        @media ${device.phone} {
            opacity: 0;
        }
    }
`;

const ControllerValue = styled.span`
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 100%;

    svg {
        flex-shrink: 0;
    }
`;

const ControllerValueText = styled.span`
    margin-left: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    color: ${colors.black.primary};
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: lowercase;
`;

const ControllerInput = styled.input`
    transition: 0.2s;
    text-align: center;
    max-width: 100%;
    position: relative;
    z-index: 1;
    display: block;
    border: none;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    color: ${colors.black.primary};
    cursor: pointer;
    padding: 19px 16px;
`;

const PassengersField = ({
                           value,
                           onChange,
                           className,
                           readOnly,
                           short,
                         }: Props) => {
  const [focus, setFocus] = useState(false);
  const isIpad = useWidthCondition((w) => w < BREAKPOINT_DESKTOP);
  const isPhone = useWidthCondition((w) => w < BREAKPOINT_IPAD);
  const [t] = useTranslation('common');
  const hasValue = Object.values(value).some((value) => value > 0);
  const selectedValues = [
    {
      value: value.adults,
      name: t("passenger"),
      name_plural: t("passengers"),
    },
  ]
    .filter(({value}) => value > 0)
    .map((item) => {
      return `${item.value} ${
        (isIpad || isPhone) && !readOnly && !short
          ? item.value > 1
            ? item.name_plural
            : item.name
          : ""
      }`;
    });

  const valueToText = selectedValues.join(", ");

  return (
    <Component className={classnames(focus && "focused", className)}>
      <Controller onClick={readOnly ? undefined : () => setFocus(true)}>
        {!readOnly && (
          <ControllerLabel
            className={classnames(hasValue && "hasValue")}
          >
            {t('passengers')}
          </ControllerLabel>
        )}

        {(readOnly || !focus) && (
          <ControllerValue>
            <IconUser/>

            <ControllerValueText>{valueToText}</ControllerValueText>
          </ControllerValue>
        )}

        {focus && (
          <ControllerInput
            autoFocus
            defaultValue=''
            onBlur={(e) => {
              !readOnly && setFocus(false);
              let newValue = Number(e.target.value);

              if (isNaN(newValue)) {
                newValue = 0;
              }

              newValue = Math.min(Math.max(1, newValue), 10);

              onChange?.(
                update(value, {
                  adults: () => newValue,
                })
              );
            }}
          />
        )}
      </Controller>
    </Component>
  );
};

export default PassengersField;
