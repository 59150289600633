import {DEFAULT_LANGUAGE, LOCAL_STORAGE_KEYS} from "constant";
import {AircraftFragment, CurrencyEnum, LanguageEnum, Passengers} from "gql/generted";
import merge from "lodash/merge";
import maxSize from "popper-max-size-modifier";
import {useEffect, useMemo, useState} from "react";
import {usePopper} from "react-popper";
import {TripConfig, TripType} from "types";

type UseDropdown = {
  onBeforeClose?(): void;
  /** @default false */
  popperMaxWidth?: boolean;
  popperOptions?: Parameters<typeof usePopper>[2];
  popperOffsetY?: number;
};

/**
 *
 * @description
 * Helpful hook to craete fully contoled dropdown component
 * 1) pass `ref` to dropdown wrapper
 * 2) use `toggle` or `setOpen` actions to control visibility
 * 3) use `open` flag to display or hide dropdown's content
 * 4) for better content position use `popperRef` to select
 * dropdown content and `popper` object to pass correct position
 * @example
 * const dropdown = useDropdown()
 *
 * <div ref={dropdown.setRef} >
 *     <button onClick={dropdown.toggle}>toggle content</div>
 *
 *     {dropdown.open && (
 *         <div
 *             ref={dropdown.setPopperRef}
 *             style={dropdown.popper.styles.popper}
 *             {...dropdown.popper.attributes.popper}
 *         >
 *             content
 *         </div>
 *     )}
 * </div>
 */
export function useDropdown({
                              onBeforeClose,
                              popperOptions,
                              popperMaxWidth = false,
                              popperOffsetY = 12,
                            }: UseDropdown = {}) {
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  // @todo check reqirement of using state
  const [popperRef, setPopperRef] = useState<
    HTMLUListElement | HTMLDivElement | null
  >(null);

  const defaultIptions: Parameters<typeof usePopper>[2] = useMemo(() => {
    return {
      placement: "bottom-start",

      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, popperOffsetY],
          },
        },

        maxSize,
        {
          name: "applyMaxSize",
          enabled: true,
          phase: "beforeWrite" as const,
          requires: ["maxSize"],
          fn({state}: any) {
            state.styles.popper = {
              ...state.styles.popper,
              maxHeight: `${
                state.modifiersData.maxSize.height - 8
              }px`,
              maxWidth: popperMaxWidth
                ? `${state.rects.reference.width}px`
                : undefined,
            };
          },
        },
      ],
    };
  }, [popperMaxWidth, popperOffsetY]);

  const popper = usePopper(
    ref,
    popperRef,
    merge(defaultIptions, popperOptions)
  );

  const close = () => {
    onBeforeClose?.();
    setOpen(false);
  };

  const toggle = () => {
    popper.forceUpdate?.();

    _setOpen(!open);
  };

  const _setOpen = (open: boolean) => {
    if (open) {
      setOpen(true);
    } else {
      close();
    }
  };

  // @todo find solution to fix wrong initial position of popper
  // const popperInstanseRef = useRef(popper)
  // popperInstanseRef.current = popper
  // useEffect(() => {
  // 	setInterval(() => {
  // 		popperInstanseRef.current.forceUpdate?.()
  // 	}, 500)
  // }, [])

  useEffect(() => {
    const mousedownListener = (e: MouseEvent) => {
      if (
        e.target &&
        !ref?.contains(e.target as Node) &&
        !popperRef?.contains(e.target as Node)
      ) {
        document.removeEventListener("mousedown", mousedownListener);
        close();
      }
    };

    // const keydownListener = (e: KeyboardEvent) => {
    //   if (e.key === "Escape") {
    //     setOpen(false);
    //     e.preventDefault();
    //     e.stopPropagation();
    //   }
    // };

    if (open) {
      document.addEventListener("mousedown", mousedownListener);
      // document.addEventListener("keydown", keydownListener);

      return () => {
        // document.removeEventListener("keydown", keydownListener);
        document.removeEventListener("mousedown", mousedownListener);
      };
    }
  }, [open, ref, popperRef]);

  return {
    open,
    toggle,
    ref,
    setRef,
    popperRef,
    setPopperRef,
    setOpen: _setOpen,
    popper,
  };
}

export const useWidthCondition = (conditionFn: (width: number) => boolean) => {
  const check = () =>
    conditionFn(typeof window !== "undefined" ? window.innerWidth : 0);

  const [condition, setCondition] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setCondition(check);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return condition;
};

export const useIsTouchableDevice = () =>
  useMemo(() => {
    return "ontouchstart" in window || window.navigator.maxTouchPoints > 0;
  }, []);

export const createTripConfig = (): TripConfig => ({
  dateTimeValue: {
    departDateTime: {date: undefined, time: undefined},
    returnDateTime: {date: undefined, time: undefined},
  },
  destinationValue: {from: undefined, to: undefined},
});

export type Currency = CurrencyEnum | null;


export const useSearchConfig = () => {
  const storredCurrency = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENCY);
  const initialCurrency =
    storredCurrency &&
    CurrencyEnum[storredCurrency as keyof typeof CurrencyEnum]
      ? (storredCurrency as Currency)
      : null;

  const [currency, _setCurrency] = useState<Currency>(initialCurrency);
  const [tripType, setTripType] = useState(TripType.ONE_WAY);
  const [trips, setTrips] = useState<TripConfig[]>([createTripConfig()]);
  const [passengers, setPassengers] = useState<Required<Passengers>>({
    adults: 1,
    children: 0,
    infants: 0,
  });

  const setCurrency = (currency: CurrencyEnum) => {
    _setCurrency(currency);
    localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENCY, currency);
  };

  return {
    tripType,
    setTripType,
    trips,
    setTrips,
    passengers,
    setPassengers,
    currency,
    setCurrency,
  };
};

export const useFormField = <T>(
  initialValue: T,
  withResetErrorOnChange = true
) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");

  return {
    value,
    error,
    change: (v: T, error = "") => {
      setValue(v);
      if (withResetErrorOnChange || error) setError(error);
    },
    changeError: setError,
  };
};

export const formatAircraftPrice = (
  flightMinPrice: AircraftFragment["flightMinPrice"],
  priceRangeDifference: AircraftFragment["priceRangeDifference"],
  currency: CurrencyEnum
) => {
  const priceAsNumber = parseInt(flightMinPrice ?? "0");
  const maxPrice = priceAsNumber + Number(priceRangeDifference);

  const format = Intl.NumberFormat("en-US", {
    currency,
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format;

  return `${format(priceAsNumber)} - ${format(maxPrice)}`;
};
export const useLanguageParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get("lang");
  if (!lang) {
    return DEFAULT_LANGUAGE
  }
  return lang
}