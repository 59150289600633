import { colors, device, typography } from "constant/styles";
import styled from "styled-components";

export const Component = styled.div`
    &.fixed {
        position: fixed;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.2) !important;
        backdrop-filter: blur(12px) !important;
        z-index: -1;
        transform: translateY(-100%);
        transition: all 0.3s ease;
        opacity: 0;

        &.show {
            opacity: 1;
            z-index: 14;
            transform: translateY(0);
        }
    }
`;

export const Form = styled.form`
    padding: 66px 26px 34px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(12px) !important;

    &.fixed {
        background: transparent !important;
        backdrop-filter: none !important;
        padding: 32px 26px 24px;
    }

    @media ${device.ipad} {
        padding: 40px 24px;

        &.fixed {
            padding: 32px 24px;
        }
    }

    @media ${device.phone} {
        padding: 24px 20px 40px;

        &.fixed {
            padding: 20px 24px;
        }
    }
`;

export const OneWayContainer = styled.div`
    display: grid;
    grid-template-columns: 180px 540px auto 130px 200px;
    gap: 0;
    grid-template-areas: "trip destination datetime passengers submit";

    @media ${device.ipad} {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px 0;
        grid-template-areas:
            "trip destination destination"
            "datetime passengers submit";
    }

    @media ${device.phone} {
        grid-template-columns: 1fr;
        gap: 0;
        grid-template-areas:
            "trip"
            "destination"
            "datetime"
            "passengers"
            "submit";
    }

    .TripTypeField {
        grid-area: trip;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;

        @media ${device.phone} {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            border-bottom-left-radius: 0;
        }
    }

    .DestinationFields {
        grid-area: destination;
        @media ${device.ipad} {
            .AirportFieldTo {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }

        @media ${device.phone} {
            .AirportFieldTo {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .DateTimeRangePicker {
        grid-area: datetime;
        @media ${device.ipad} {
            .DepartField {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }
        }

        @media ${device.phone} {
            .DepartField {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .PassengersField {
        grid-area: passengers;
        @media ${device.ipad} {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
        }
        @media ${device.phone} {
            border-top-right-radius: 0;
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
        }
    }

    .SubmitBtn {
        grid-area: submit;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;

        @media ${device.ipad} {
            margin-left: 12px;
            border-radius: 12px;
        }

        @media ${device.phone} {
            margin: 12px auto 0;
        }
    }
`;

export const RoundTripContainer = styled.div`
    display: grid;
    grid-template-columns: 160px 370px auto 130px 200px;
    gap: 0;
    grid-template-areas: "trip destination datetime passengers submit";

    @media ${device.ipad} {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 40px 0;
        grid-template-areas:
            "trip destination destination destination destination"
            "datetime datetime datetime passengers submit";
    }

    @media ${device.phone} {
        grid-template-columns: 1fr;
        gap: 0;
        grid-template-areas:
            "trip"
            "destination"
            "datetime"
            "passengers"
            "submit";
    }

    .TripTypeField {
        grid-area: trip;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;

        @media ${device.phone} {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            border-bottom-left-radius: 0;
        }
    }

    .DestinationFields {
        grid-area: destination;
        @media ${device.ipad} {
            .AirportFieldTo {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }

        @media ${device.phone} {
            .AirportFieldTo {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .DateTimeRangePicker {
        grid-area: datetime;
        @media ${device.ipad} {
            .DepartField {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }
        }

        @media ${device.phone} {
            .DepartField {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .PassengersField {
        grid-area: passengers;
        @media ${device.ipad} {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
        }
        @media ${device.phone} {
            border-top-right-radius: 0;
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
        }
    }

    .SubmitBtn {
        grid-area: submit;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;

        @media ${device.ipad} {
            margin-left: 12px;
            border-radius: 12px;
        }

        @media ${device.phone} {
            margin: 12px auto 0;
        }
    }
`;

export const MultiLegContainer = styled.div`
    .TripTypeField {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    .PassengersField {
        border-radius: 12px;
        margin-right: 12px;

        @media ${device.phone} {
            width: 100%;
        }
    }

    .SubmitBtn {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;

        @media ${device.ipad} {
            border-radius: 12px;
            width: 300px;
        }
    }
`;

export const MultiLegRow = styled.div`
    display: grid;
    grid-template-columns: 176px 1fr 200px;
    margin-bottom: 40px;

    &:not(:first-child) {
        .DestinationFields {
            .AirportFieldFrom {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }
        }

        .DateTimeRangePicker {
            .DepartField {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }

    @media ${device.ipad} {
        grid-template-columns: 176px 1fr;

        .DateTimeRangePicker {
            .DepartField {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }

    @media ${device.phone} {
        grid-template-columns: 1fr;

        .DestinationFields {
            .AirportFieldFrom {
                border-bottom-left-radius: 0 !important;
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
            }
        }

        .DateTimeRangePicker {
            .DepartField {
                border-top-right-radius: 0 !important;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const MultiLegFields = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 300px;

    @media ${device.phone} {
        grid-template-columns: 1fr;
    }
`;

export const MultiLegRemoveBtn = styled.button`
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${device.phone} {
        bottom: unset;
        top: -10px;
    }
`;

export const MultiLegAddBtn = styled.button`
    color: ${colors.white.primary};
    font-size: 16px;
    line-height: 121x;
    font-family: ${typography.fontFamily};
    font-style: normal;
    font-weight: 500;
    padding: 0;
    margin: 0;
`;

export const MultiLegActions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > * {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const SubmitBtn = styled.button`
    height: 60px;
    flex-shrink: 0;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    background-color: ${colors.black.primary};
    font-family: ${typography.fontFamily};
    color: ${colors.white.primary};
    font-style: normal;
    font-weight: bold;

    &.fluid {
        width: 100%;
        max-width: 100%;
    }

    @media ${device.ipad} {
        font-size: 28px;
        line-height: 26px;
    }

    @media ${device.phone} {
        max-width: 280px;
        width: 100%;
        font-size: 20px;
        line-height: 26px;
    }

    &:hover {
        background: rgba(34, 37, 45, 0.9);
    }

    &:active {
        background: rgba(34, 37, 45, 0.9);
    }
`;

export const PriceText = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    font-family: ${typography.fontFamily};
    color: ${colors.white.primary};
    margin: 32px auto 0;
    text-align: center;
    max-width: 520px;

    @media ${device.phone} {
        margin: 0 auto 32px;
    }
`;

export const CurrencySelect = styled.select`
    background: transparent;
    border:none;
    padding: 2px 6px;
    border-radius: 4px;
    color: white;
    position: absolute;
    right: 20px;
    bottom: 20px;

    @media ${device.ipad} {
        bottom: 8px;
    }
`;
