import { colors, device } from "constant/styles";
import styled from "styled-components";

export const Container = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    overflow-y: visible;

    @media ${device.ipad} {
        padding: 0;
    }
`;

export const DropdownContent = styled.div`
    background: ${colors.white.primary};
    border: 1px solid ${colors.grey.secondary};
    box-shadow: 0px 12px 60px rgba(89, 89, 89, 0.1);
    transition: 0.2s;
    border-radius: 16px;
    overflow: hidden;
    z-index: 5;

    &:not([data-open]) {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }
`;

export const ScrollbarContainer = styled.div`
    scrollbar-width: thin;
    scrollbar-color: #888686 #dfe9eb;

    &::-webkit-scrollbar {
        width: 10px;
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: ${colors.grey.secondary};
    }

    &::-webkit-scrollbar-track:hover {
        background-color: ${colors.grey.secondary};
    }

    &::-webkit-scrollbar-track:active {
        background-color: ${colors.grey.secondary};
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #888686;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #888686;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: #888686;
    }
`;

export const TextFieldContainer = styled.div`
    position: relative;
    margin-bottom: 16px;
`;
