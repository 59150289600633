import classNames from "classnames";
import { IconAngleLeft } from "components/Icons";
import { DropdownContent, ScrollbarContainer } from "components/UI";
import { BREAKPOINT_IPAD } from "constant";
import { colors, device, typography } from "constant/styles";
import { useDropdown, useWidthCondition } from "helpers";
import styled from "styled-components";
import { TripType } from "types";
import {useTranslation} from "react-i18next";

type Props = {
    readOnly?: true;
    value: TripType;
    onChange: (v: TripType) => void;
    className?: string;
};

const Component = styled.div`
    border: 1px solid transparent;
    background: ${colors.white.primary};
    height: 60px;
    border-left-color: ${colors.grey.secondary};
    transition: 0.2s border-color;
    position: relative;

    @media ${device.phone} {
        border-left-color: transparent;
        border-bottom-color: ${colors.grey.secondary};
    }

    &.focused {
        border-color: ${colors.black.primary};
    }
`;

const Controller = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
`;

const ControllerLabel = styled.span`
    position: absolute;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: ${typography.fontFamily};
    transition: 0.2s;
    color: ${colors.white.primary};
    transform: translateY(-80%);
    left: 16px;
    top: -8px;
    opacity: 0;

    &.hasValue {
        opacity: 1;
        transform: translateY(-100%);

        @media ${device.phone} {
            opacity: 0;
        }
    }
`;

const ControllerValue = styled.input`
    position: relative;
    z-index: 1;
    display: inline-block;
    border: none;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    cursor: pointer;
    text-align: left;
    padding: 19px 16px;
    color: ${colors.black.primary};

    &.readOnly {
        cursor: default;
    }

    &::placeholder {
        color: ${colors.grey.dark};
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        font-family: ${typography.fontFamily};
    }
`;

const ControllerArrow = styled(IconAngleLeft)<{ $rotate: boolean }>`
    position: absolute;
    top: 50%;
    transition: 0.2s;
    right: 12px;
    transform: translateY(-50%)
        ${({ $rotate }) => ($rotate ? "rotate(90deg)" : "rotate(-90deg)")};
`;

const Content = styled(DropdownContent)`
    border: none;
    width: 160px;

    @media ${device.phone} {
        width: calc(100% + 32px);
    }
`;

const ContentWrapper = styled(ScrollbarContainer)`
    max-height: 360px;
    overflow-x: hidden;
`;

const Option = styled.li`
    padding: 0 16px;
    transition: 0.2s;

    &:hover {
        background-color: #29247a;

        button {
            color: #fff;
            border-bottom-color: #29247a;
        }
    }

    button {
        transition: 0.2s;
        border-bottom: 1px solid ${colors.grey.secondary};
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 12px 0;
        align-items: stretch;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        font-family: ${typography.fontFamily};
        color: ${colors.black.primary};
        text-align: left;
    }
`;

const TripTypeField = ({ value, onChange, className, readOnly }: Props) => {
    const {t} = useTranslation('common')
    const isPhone = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const dropdown = useDropdown({
        popperOffsetY: isPhone ? 0 : undefined,
        popperOptions: {
            placement: isPhone ? "bottom" : "bottom-start",
        },
    });

    const upLabel = value || dropdown.open;

    const onSelectOption = (newValue: TripType) => {
        onChange(newValue);
        dropdown.setOpen(false);
    };

    return (
        <Component
            ref={dropdown.setRef}
            className={classNames(dropdown.open && "focused", className)}
        >
            <Controller>
                {!readOnly && (
                    <ControllerLabel
                        className={classNames(upLabel && "hasValue")}
                    >
                        {t('tripType')}
                    </ControllerLabel>
                )}
                <ControllerValue
                    readOnly
                    onClick={dropdown.toggle}
                    className={classNames(readOnly && "readOnly")}
                    value={value}
                />

                <ControllerArrow $rotate={dropdown.open} />
            </Controller>

            <Content
                ref={dropdown.setPopperRef}
                style={dropdown.popper.styles.popper}
                {...dropdown.popper.attributes.popper}
                data-open={dropdown.open || undefined}
            >
                <ContentWrapper>
                    <ul>
                        <Option>
                            <button
                                type="button"
                                onClick={() => onSelectOption(TripType.ONE_WAY)}
                            >
                                {t(TripType.ONE_WAY)}
                            </button>
                        </Option>
                        <Option>
                            <button
                                type="button"
                                onClick={() =>
                                    onSelectOption(TripType.ROUND_TRIP)
                                }
                            >
                                {t(TripType.ROUND_TRIP)}
                            </button>
                        </Option>

                        <Option>
                            <button
                                type="button"
                                onClick={() =>
                                    onSelectOption(TripType.MULTI_LEG)
                                }
                            >
                                {t(TripType.MULTI_LEG)}
                            </button>
                        </Option>
                    </ul>
                </ContentWrapper>
            </Content>
        </Component>
    );
};

export default TripTypeField;
