import React from "react";
import ReactDOM from "react-dom/client";
import "scss/global.scss";
import Widget from "./Widget";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import './assets/locales';


const client = new ApolloClient({
    uri: process.env.REACT_APP_API,
    cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
    document.getElementById("israjets-embedded-widget") as HTMLElement
);

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Widget />
        </ApolloProvider>
    </React.StrictMode>
);
