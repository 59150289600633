import { AirportFragment } from "gql/generted";
import { useSearchConfig } from "helpers";
import { DateTime } from "luxon";

export type DateTimeValue = {
    date: DateTime | undefined;
    time: { hour: number; minute: number } | undefined;
};

export type DestinationValue = {
    from: AirportFragment | undefined;
    to: AirportFragment | undefined;
};

export type TripConfig = {
    dateTimeValue: {
        departDateTime: DateTimeValue;
        returnDateTime: DateTimeValue;
    };
    destinationValue: DestinationValue;
};

export enum TripType {
    ONE_WAY = 'One way flight ',
    ROUND_TRIP = 'Round trip',
    MULTI_LEG = 'Multi leg'
}

export type UseSearchConfig = ReturnType<typeof useSearchConfig>;

