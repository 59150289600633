import { colors } from "constant/styles";
import styled from "styled-components";

type Props = {
    slideTo?(index: number): void;
    length: number;
    activeSlide: number;
};

const Container = styled.ul`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    z-index: 3;
    bottom: 20px;
    left: 0;
    width: 100%;

    li {
        margin: 0 8px;
        display: flex;

        button {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            position: relative;
            border: 2px solid ${colors.white.primary};

            &.active::before {
                position: absolute;
                content: "";
                top: 50%;
                left: 50%;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                background: ${colors.white.primary};
                width: 6px;
                height: 6px;
            }
        }
    }
`;

const SwiperDots = (props: Props) => {
    return (
        <Container>
            {new Array(props.length).fill(null).map((_, index) => {
                return (
                    <li key={index}>
                        <button
                            onClick={() => props.slideTo?.(index)}
                            className={
                                index === props.activeSlide
                                    ? "active"
                                    : undefined
                            }
                        />
                    </li>
                );
            })}
        </Container>
    );
};

export default SwiperDots;
