import { BREAKPOINT_DESKTOP, BREAKPOINT_IPAD } from "constant";

export const device = {
    ipad: `(max-width: ${BREAKPOINT_DESKTOP - 0.2}px)`,
    phone: `(max-width: ${BREAKPOINT_IPAD - 0.2}px)`,
};

export const typography = {
    fontFamily: '"Poppins", sans-serif',
};

export const colors = {
    black: {
        primary: "#22252d",
        secondary: "rgba(34, 37, 45, 0.5)",
    },
    grey: {
        primary: "#dadada",
        secondary: "#e6e8ec",
        dark: "#888686",
    },
    white: {
        primary: "#fafafa",
    },
    red: {
        primary: "#ff543d",
    },
};
