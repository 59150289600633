import { IconSeats, IconSpeedometer } from "components/Icons";
import SwiperDots from "components/UI/SwiperDots";
import SwiperNavBtn from "components/UI/SwiperNavBtn";
import SwiperSlideImage from "components/UI/SwiperSlideImage";
import { AIRCRAFT_IMAGES, BREAKPOINT_IPAD } from "constant";
import { AircraftFragment, CurrencyEnum } from "gql/generted";
import { formatAircraftPrice, useWidthCondition } from "helpers";
import { useRef, useState } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperCore } from "swiper/types";
import { TripType } from "types";
import {
    AicraftName,
    AircraftParam,
    AircraftParamIcon,
    AircraftParamLabel,
    AircraftParamValueLegs,
    AircraftParamValueSeats,
    AircraftParams,
    AircraftPriceRange,
    AircraftTypes,
    BtnInquire,
    Container,
    MainContent,
    PriceNote,
    SwiperContainer,
} from "./components";
import {useTranslation} from "react-i18next";

type Props = {
    currency: CurrencyEnum;
    tripType: TripType;
    data: AircraftFragment;
    onClickInquire?(): void;
};

const AircraftCard = ({ data, onClickInquire, tripType, currency }: Props) => {
    const [t] = useTranslation('common')
    const images = AIRCRAFT_IMAGES[data.aircraftType];
    const swiperRef = useRef<SwiperCore>();
    const isPhone = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const Types = (
        <AircraftTypes>
            <b>{t('types')}:</b>
            {data.jets}
        </AircraftTypes>
    );

    const price = formatAircraftPrice(
        data.flightMinPrice,
        data.priceRangeDifference,
        currency
    );

    return (
        <Container>
            <SwiperContainer>
                <Swiper
                    slidesPerView={1}
                    loop
                    onSlideChange={(e) => setActiveSlideIndex(e.activeIndex)}
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                >
                    {images.map((img, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <SwiperSlideImage src={img} alt="" />
                            </SwiperSlide>
                        );
                    })}

                    <SwiperDots
                        length={images.length}
                        activeSlide={activeSlideIndex}
                        slideTo={(index) => swiperRef.current?.slideTo(index)}
                    />

                    <SwiperNavBtn
                        onClick={() => swiperRef.current?.slidePrev()}
                        $direction="prev"
                    />

                    <SwiperNavBtn
                        onClick={() => swiperRef.current?.slideNext()}
                        $direction="next"
                    />
                </Swiper>
            </SwiperContainer>

            <AircraftParams>
                <div>
                    <AircraftParam>
                        <AircraftParamIcon>
                            <IconSeats />
                        </AircraftParamIcon>
                        <AircraftParamLabel>{t('seats')}</AircraftParamLabel>
                        <AircraftParamValueSeats>
                            {data.seats}
                        </AircraftParamValueSeats>
                    </AircraftParam>
                    {tripType === TripType.ONE_WAY && (
                        <AircraftParam>
                            <AircraftParamIcon>
                                <IconSpeedometer />
                            </AircraftParamIcon>

                            <AircraftParamLabel>{t('timeOnAir')}</AircraftParamLabel>
                            <AircraftParamValueLegs>
                                <li>
                                    {Math.trunc(
                                        data.flightDurationInMinutes / 60
                                    )}
                                    {t('hours')} {data.flightDurationInMinutes % 60}
                                    {t('minutes')}
                                </li>
                            </AircraftParamValueLegs>
                        </AircraftParam>
                    )}

                    {!isPhone && Types}
                </div>
            </AircraftParams>

            <MainContent>
                <AicraftName $long={data.name.length > 10}>
                    {data.name}
                </AicraftName>

                <AircraftPriceRange $long={price.length >= 16}>
                    <span>{t('priceRange')}</span>
                    {price}
                </AircraftPriceRange>

                <BtnInquire type="button" onClick={onClickInquire}>
                    {t("inquire")}
                </BtnInquire>
                <PriceNote>{t('estimatedQuote')}</PriceNote>

                {isPhone && Types}
            </MainContent>
        </Container>
    );
};

export default AircraftCard;
