import classnames from "classnames";
import {colors, typography} from "constant/styles";
import {DateTime, Info} from "luxon";
import {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const WEEKDAYS_EN = Info.weekdays("short", {locale: 'en'}).map((name) =>
  name.slice(0, 2)
);

type Props = {
  month: number;
  year: number;
  onClickDate(v: DateTime): void;
  minDate?: DateTime | undefined;
  maxDate?: DateTime | undefined;
  startDate?: DateTime | undefined;
  endDate?: DateTime | undefined;
  hoveredDate?: DateTime | undefined;
  onHoverDate?(v: DateTime | undefined): void;
};

const Component = styled.div`
    max-width: 280px;
`;

const MonthYear = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    color: ${colors.black.primary};
    margin: 0 0 16px;
    text-align: center;
    text-transform: uppercase;
`;

const Row = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 18px;
    font-size: 0;

    &:last-child {
        margin-bottom: 0;
    }
`;

const Weekdays = styled(Row)`
    margin-bottom: 8px;
`;

const Weekday = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    text-align: center;
    color: ${colors.black.primary};
`;

const Date = styled.button`
    width: 24px;
    height: 100%;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: ${typography.fontFamily};
    text-align: center;
    transition: 0.2s;
    color: ${colors.black.primary};

    &:disabled {
        cursor: default;
        color: ${colors.grey.primary};
    }

    &:not(:disabled) {
        &:hover {
            background-color: ${colors.grey.secondary};
        }

        &.inRange {
            background-color: ${colors.grey.secondary};
        }

        &.selected {
            color: ${colors.white.primary};
            background-color: ${colors.black.primary};
        }
    }
`;

const Ceil = styled.div`
    width: 40px;
    height: 20px;
    display: flex;
    justify-content: center;

    &::before,
    &::after {
        content: "";
        height: 100%;
        flex-grow: 1;
        opacity: 0;
        transition: 0.2s;
        background: ${colors.grey.secondary};
    }

    &.before {
        ${Date} {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &::before {
            opacity: 1;
        }
    }

    &.after {
        ${Date} {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &::after {
            opacity: 1;
        }
    }
`;

const Calendar = ({
                    month,
                    year,
                    onClickDate,
                    minDate,
                    maxDate,
                    hoveredDate,
                    startDate,
                    endDate,
                    onHoverDate,
                  }: Props) => {
  const monthToShowDateTime = useMemo(() => {
    return DateTime.local().set({
      month,
      year,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }, [month, year]);
  const [_, {language}] = useTranslation()
  const [weekdays, setWeekdays] = useState(WEEKDAYS_EN)
  const days = useMemo(() => {
    const calendar: (DateTime | null)[] = [];
    const firstDayInMonth = monthToShowDateTime.set({day: 1});
    const lastDayInMonth = monthToShowDateTime.set({
      day: firstDayInMonth.daysInMonth,
    });

    const daysInCurrentMonth = firstDayInMonth.daysInMonth!;
    calendar.push(firstDayInMonth);

    // DAYS IN CURRENT MONTH
    for (let i = 1; i < daysInCurrentMonth; i++) {
      calendar.push(firstDayInMonth.plus({days: i}));
    }

    // empty ceils for previous and next monthes
    calendar.unshift(...new Array(firstDayInMonth.weekday - 1).fill(null));
    calendar.push(...new Array(7 - lastDayInMonth.weekday).fill(null));

    return calendar;
  }, [monthToShowDateTime]);

  const weeksWithDays = useMemo(() => {
    let weeks = [];
    for (let i = 0; i < days.length; i += 7) {
      weeks.push(days.slice(i, i + 7));
    }
    return weeks;
  }, [days]);

  const getDateClassNames = (day: DateTime | null) => {
    const isStartDate = day && day.toISODate() === startDate?.toISODate();
    const isEndDate = day && day.toISODate() === endDate?.toISODate();
    const isSelected = isStartDate || isEndDate;

    const range = {
      start: [startDate, endDate, hoveredDate]
        .filter(Boolean)
        .sort((a, b) => (a as any) - (b as any))[0],
      end: [startDate, endDate, hoveredDate]
        .filter(Boolean)
        .sort((a, b) => (b as any) - (a as any))[0],
    };

    const hasRange = range.start && range.end;

    const inRange =
      day && hasRange && range.start! <= day && day <= range.end!;

    const before = inRange && day <= range.end! && day > range.start!;
    const after = inRange && day >= range.start! && day < range.end!;

    return {
      ceil: classnames({
        before: before,
        after: after,
      }),
      button: classnames({
        selected: isSelected,
        inRange: inRange,
      }),
    };
  };
  useEffect(() => {
    console.log(language)

    if (language !== 'en') {
      setWeekdays(
        Info.weekdays("short", {locale: language}).map((name) =>
          name.slice(0, 2)
        ))
    }
  }, [language]);
  return (
    <Component>
      <MonthYear>{monthToShowDateTime.toFormat("LLL yyyy")}</MonthYear>
      <Weekdays>
        {weekdays.map((weekday) => (
          <Weekday key={weekday}>{weekday}</Weekday>
        ))}
      </Weekdays>

      {weeksWithDays.map((weekWithDays, i) => {
        return (
          <Row key={i}>
            {weekWithDays.map((day, i) => {
              const cls = getDateClassNames(day);

              const disabled =
                !day ||
                (minDate && day < minDate) ||
                (maxDate && day > maxDate);

              return (
                <Ceil
                  className={cls.ceil}
                  key={day?.toMillis() ?? i}
                >
                  <Date
                    onClick={() => onClickDate(day!)}
                    type="button"
                    onMouseEnter={() =>
                      day ? onHoverDate?.(day) : undefined
                    }
                    onMouseLeave={() =>
                      day
                        ? onHoverDate?.(undefined)
                        : undefined
                    }
                    disabled={disabled}
                    className={cls.button}
                  >
                    {day?.day}
                  </Date>
                </Ceil>
              );
            })}
          </Row>
        );
      })}
    </Component>
  );
};

export default Calendar;
