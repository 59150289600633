import { default as Flags } from "country-flag-icons/react/3x2";
import { useDropdown, useWidthCondition } from "helpers";

import classNames from "classnames";
import { DropdownContent, ScrollbarContainer } from "components/UI";
import { BREAKPOINT_DESKTOP, BREAKPOINT_IPAD } from "constant";
import { colors, device, typography } from "constant/styles";
import { AirportFragment, useAirportsLazyQuery } from "gql/generted";
import {
    ChangeEvent,
    Fragment,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import { DestinationValue } from "types";

type Props = {
    readOnly?: true;
    value: DestinationValue["from"];
    onChange: (v: DestinationValue["from"]) => void;
    className?: string;
    label: string;
};

const Component = styled.div`
    border: 1px solid transparent;
    background: ${colors.white.primary};
    height: 60px;
    border-left-color: ${colors.grey.secondary};
    transition: 0.2s border-color;

    @media ${device.phone} {
        border-left-color: transparent;
        border-bottom-color: ${colors.grey.secondary};
    }

    &.focused {
        border-color: ${colors.black.primary};
    }
`;

const Controller = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
`;

const Label = styled.span`
    position: absolute;
    text-transform: uppercase;
    font-family: ${typography.fontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    transition: 0.2s;
    color: ${colors.white.primary};
    transform: translateY(-80%);
    left: 16px;
    top: -8px;
    opacity: 0;

    &.hasValue {
        opacity: 1;
        transform: translateY(-100%);

        @media ${device.phone} {
            opacity: 0;
        }
    }
`;

const Input = styled.input`
    position: relative;
    z-index: 1;
    display: inline-block;
    border: none;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    cursor: pointer;
    padding: 0;
    text-align: left;
    padding: 19px 60px 19px 16px;
    color: ${colors.black.primary};

    &::placeholder {
        color: ${colors.grey.dark};
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        font-family: ${typography.fontFamily};
    }

    &.focused {
        padding-right: 16px;
    }

    &.readOnly {
        cursor: default;
    }
`;

const AirportCode = styled.span`
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(40, 43, 51, 0.7);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    color: ${colors.black.secondary};
`;

const Content = styled(DropdownContent)`
    border: none;
    width: 343px;

    @media ${device.phone} {
        width: calc(100% + 32px);
    }
`;

const ContentWrapper = styled(ScrollbarContainer)`
    max-height: 360px;
    overflow-x: hidden;
`;

const AirportOption = styled.li`
    padding: 0 16px;
    transition: 0.2s;

    &:hover {
        background-color: ${colors.grey.secondary};
    }
`;

const AirportOptionBtn = styled.button`
    padding: 0;
    border-bottom: 1px solid ${colors.grey.secondary};
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    align-items: stretch;

    &.smallPadding {
        padding-bottom: 8px;
    }
`;

const AirportOptionMain = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const AirportOptionLocate = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const AirportOptionFlag = styled.div`
    height: 14px;
    width: 20px;
    display: flex;
    margin-right: 8px;
    border-radius: 4px;
    overflow: hidden;

    svg {
        width: 100%;
        height: 100%;
    }
`;

const AirportOptionName = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    color: ${colors.black.primary};
    text-align: left;
    padding-right: 4px;
`;

const AirportOptionCode = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    color: rgba(40, 43, 51, 0.7);
`;

const AirportOptionTag = styled.span`
    padding: 4px 8px;
    border-radius: 8px;
    background: rgba(80, 163, 113, 0.1);
    color: #51b37f;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    font-family: ${typography.fontFamily};
    display: block;
    width: fit-content;
    margin-top: 4px;
`;

const AirportOptionAdditionalInfo = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: ${typography.fontFamily};
    text-align: left;
    color: rgba(34, 37, 45, 0.5);
    margin-top: 4px;
`;

const NoResultText = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 45px;
    font-family: ${typography.fontFamily};
    color: ${colors.black.secondary};
    margin: 0;
    padding: 0 16px;
`;

const LIVE_SEARTCH_TIMEOUT = 0;
const MIN_LENGTH_TO_SEARCH = 3;

const AirportField = ({
    value,
    onChange,
    className,
    label,
    readOnly,
}: Props) => {
    const isIpad = useWidthCondition((w) => w < BREAKPOINT_DESKTOP);
    const isPhone = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const [focused, setFocused] = useState(false);
    const [inputting, setInputting] = useState(false);
    const [searchText, setSearchText] = useState("");
    const lastSearchText = useRef(searchText);
    const [airportsQuery, airportsQueryParams] = useAirportsLazyQuery();
    const [foundAirports, setFoundAirports] = useState<AirportFragment[]>([]);

    const queriedAirports = airportsQueryParams.data?.airports.airports;

    useEffect(() => {
        if (queriedAirports?.length) {
            setFoundAirports(queriedAirports);
        }
    }, [queriedAirports]);

    const showStartLocation = !value && !searchText;

    const dropdown = useDropdown({
        popperOffsetY: isPhone ? 0 : isIpad ? -15 : undefined,
        popperOptions: {
            placement: isPhone ? "bottom" : "bottom-start",
        },
        onBeforeClose: () => {
            if (lastSearchText.current === "") {
                onChange(undefined);
            }

            setSearchText("");
        },
    });

    const upLabel = Boolean(focused || dropdown.open || value);

    const valueAsText = value?.name || value?.cityName || "";
    const airportCode = value?.code;
    const inputValue = focused ? searchText : valueAsText;

    const onFocusInput: React.FocusEventHandler<HTMLInputElement> = (e) => {
        if (readOnly) return;

        const input = e.target;
        const end = input.value.length;

        setTimeout(() => {
            input.setSelectionRange(end, end);
            input.focus();
        });

        setFocused(true);

        if (!dropdown.open) {
            setSearchText(valueAsText);
            dropdown.setOpen(true);
        }
    };

    const onBlurInput = () => {
        setFocused(false);
    };

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.currentTarget.value);
    };

    const onSelectAirport = (airport: AirportFragment) => {
        setSearchText(searchText || " ");
        onChange(airport);
        setTimeout(() => dropdown.setOpen(false));
    };

    useEffect(() => {
        lastSearchText.current = searchText;
        setInputting(true);

        const tid = setTimeout(() => {
            setInputting(false);
            airportsQuery({
                variables: {
                    q: searchText,
                },
            }).catch(console.error);
        }, LIVE_SEARTCH_TIMEOUT);

        return () => {
            clearTimeout(tid);
        };
    }, [searchText]);

    return (
        <Component
            ref={dropdown.setRef}
            className={classNames(dropdown.open && "focused", className)}
        >
            <Controller>
                {!readOnly && (
                    <Label className={classNames(upLabel && "hasValue")}>
                        {label}
                    </Label>
                )}
                <Input
                    placeholder={upLabel ? "" : label}
                    onFocus={onFocusInput}
                    onBlur={onBlurInput}
                    onChange={onChangeInput}
                    value={inputValue}
                />

                {!dropdown.open && <AirportCode>{airportCode}</AirportCode>}
            </Controller>

            <Content
                ref={dropdown.setPopperRef}
                style={dropdown.popper.styles.popper}
                {...dropdown.popper.attributes.popper}
                data-open={dropdown.open || undefined}
            >
                <ContentWrapper>
                    {!showStartLocation &&
                        searchText.length >= MIN_LENGTH_TO_SEARCH &&
                        (foundAirports.length ? (
                            <ul>
                                {foundAirports.map((airport) => (
                                    <AirportItem
                                        data={airport}
                                        searchText={searchText}
                                        key={airport.id}
                                        onSelect={() =>
                                            onSelectAirport(airport)
                                        }
                                    />
                                ))}
                            </ul>
                        ) : airportsQueryParams.loading || inputting ? null : (
                            <NoResultText>No results found</NoResultText>
                        ))}
                </ContentWrapper>
            </Content>
        </Component>
    );
};

const AirportItem = ({
    data,
    onSelect,
    searchText,
}: {
    searchText: string;
    data: AirportFragment;
    onSelect(): void;
}) => {
    const showAdditionalInfo = !!data.name || !!data.destinationFromUserInKm;
    const additionalInfo =
        data.countryName || `${data.destinationFromUserInKm}km away`;

    const FlagComponent =
        data.countryCode && (Flags as any)[data.countryCode.toUpperCase()];
    const name = useMemo(() => {
        const fullName =
            data.name || [data.cityName, data.countryName].join(", ");

        if (searchText) {
            const index = fullName
                .toLowerCase()
                .indexOf(searchText.toLowerCase());

            if (index >= 0) {
                return (
                    <Fragment>
                        {fullName.slice(0, index)}
                        <strong>
                            {fullName.slice(index, index + searchText.length)}
                        </strong>
                        {fullName.slice(index + searchText.length)}
                    </Fragment>
                );
            }
        }

        return fullName;
    }, [searchText, data.cityName, data.name, data.countryName]);

    return (
        <AirportOption>
            <AirportOptionBtn
                tabIndex={-1}
                onClick={onSelect}
                type="button"
                className={classNames({
                    smallPadding: showAdditionalInfo,
                })}
            >
                <AirportOptionMain>
                    <AirportOptionLocate>
                        <AirportOptionFlag>
                            {FlagComponent && <FlagComponent />}
                        </AirportOptionFlag>
                        <AirportOptionName>{name}</AirportOptionName>
                    </AirportOptionLocate>

                    <AirportOptionCode>
                        {data.code.toUpperCase()}
                    </AirportOptionCode>
                </AirportOptionMain>

                {showAdditionalInfo && (
                    <AirportOptionAdditionalInfo>
                        {additionalInfo}
                    </AirportOptionAdditionalInfo>
                )}

                {data.bestPrice && (
                    <AirportOptionTag>better price</AirportOptionTag>
                )}
            </AirportOptionBtn>
        </AirportOption>
    );
};

export default AirportField;
