import { AircraftType } from "gql/generted";

export const BREAKPOINT_DESKTOP = 1440;
export const BREAKPOINT_IPAD = 1024;

export const AIRCRAFT_IMAGES = {
    [AircraftType.HeavyJet]: [
        require("assets/images/aricraftCategories/HEAVY_JET/1.jpg"),
        require("assets/images/aricraftCategories/HEAVY_JET/3.jpg"),
        require("assets/images/aricraftCategories/HEAVY_JET/2.jpg"),
    ],
    [AircraftType.LightJet]: [
        require("assets/images/aricraftCategories/LIGHT_JET/1.jpg"),
        require("assets/images/aricraftCategories/LIGHT_JET/3.jpg"),
        require("assets/images/aricraftCategories/LIGHT_JET/2.jpg"),
    ],
    [AircraftType.MidSizeJet]: [
        require("assets/images/aricraftCategories/MID_SIZE_JET/1.jpg"),
        require("assets/images/aricraftCategories/MID_SIZE_JET/3.jpg"),
        require("assets/images/aricraftCategories/MID_SIZE_JET/2.jpg"),
    ],
    [AircraftType.SuperMidJet]: [
        require("assets/images/aricraftCategories/SUPER_MID_JET/1.jpg"),
        require("assets/images/aricraftCategories/SUPER_MID_JET/3.jpg"),
        require("assets/images/aricraftCategories/SUPER_MID_JET/2.jpg"),
    ],
    [AircraftType.TurboProp]: [
        require("assets/images/aricraftCategories/TURBO_PROP/1.jpg"),
        require("assets/images/aricraftCategories/TURBO_PROP/3.jpg"),
        require("assets/images/aricraftCategories/TURBO_PROP/2.jpg"),
    ],
    [AircraftType.UltraLongRangeJet]: [
        require("assets/images/aricraftCategories/ULTRA_LONG_RANGE_JET/1.jpg"),
        require("assets/images/aricraftCategories/ULTRA_LONG_RANGE_JET/3.jpg"),
        require("assets/images/aricraftCategories/ULTRA_LONG_RANGE_JET/2.jpg"),
    ],
};


export const LOCAL_STORAGE_KEYS = {
    CURRENCY: "currency",
}

export const DEFAULT_LANGUAGE = "en";