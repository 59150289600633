import {LanguageEnum} from "../gql/generted";
import {DEFAULT_LANGUAGE} from "../constant";

export const LANGUAGES = [
  LanguageEnum.En,
  LanguageEnum.Fr,
  LanguageEnum.Es,
]

export const getLanguage = (language: string) => {
  return LANGUAGES.includes(language.toUpperCase() as LanguageEnum)
    ? language.toLowerCase() as LanguageEnum
    : DEFAULT_LANGUAGE;
}
