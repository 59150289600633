import classNames from "classnames";
import { FocusEventHandler, useMemo, useState } from "react";
import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Label from "components/UI/Label";
import { TextFieldContainer } from "components/UI";
import styled from "styled-components";
import { colors, typography } from "constant/styles";
import { useGeoipQuery } from "gql/generted";

type Props = {
    label: string;
    error?: string;
    value: string;
    onChange(
        v: string,
        config: CountryData,
        event: any,
        formattedValue: string
    ): void;
};

const PhoneFieldContainer = styled.div`
    display: flex;
    height: 54px;
    border-radius: 8px;
    background: ${colors.white.primary};
    border: 1px solid transparent;
    transition: 0.2s border-color;
    padding: 0;

    &.focused {
        border-color: ${colors.black.primary};
    }

    &.hasError {
        border-color: ${colors.red.primary}!important;
    }

    .Input {
        height: 100%;
        flex-grow: 1;
        background: transparent;
        border-radius: 0;
        text-align: left;
        padding: 19px 16px 19px 92px;
        border: none;

        color: #22252d;
        font-family: ${typography.fontFamily};
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &::placeholder {
            color: ${colors.grey.dark};
            font-family: ${typography.fontFamily};
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .InputController {
        width: 82px;
        top: 0;
        left: 0;
        border: none;
        border-right: 1px solid ${colors.grey.secondary};
        flex-shrink: 0;
        background: transparent !important;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;

        & > div {
            padding: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
        }
    }
`;

const PhoneInput = ({ error, label, value, onChange }: Props) => {
    const id = useMemo(() => `text-field-${Math.random()}`, []);
    const geoipQuery = useGeoipQuery();

    const [focused, setFocused] = useState(false);
    const hasError = Boolean(error);

    const onFocusInput: FocusEventHandler<HTMLInputElement> = (e) => {
        setFocused(true);
    };

    const onBlurInput: FocusEventHandler<HTMLInputElement> = (e) => {
        setFocused(false);
    };

    return (
        <TextFieldContainer>
            <Label htmlFor={id}>{label}</Label>
            <PhoneFieldContainer
                className={classNames({
                    focused: focused,
                    hasError: hasError,
                })}
            >
                <ReactPhoneInput
                    inputProps={{
                        id,
                    }}
                    masks={{ il: "... .InquireUpdate.. ..." }}
                    country={geoipQuery.data?.geoip.country?.toLowerCase() ?? "us"}
                    value={value}
                    onFocus={onFocusInput}
                    onBlur={onBlurInput}
                    onChange={onChange}
                    inputClass={classNames("Input")}
                    containerClass={classNames("InputContainer")}
                    buttonClass={classNames("InputController")}
                />
            </PhoneFieldContainer>
        </TextFieldContainer>
    );
};

export default PhoneInput;
