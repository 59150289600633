import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';

import commonEn from './en/common.json';
import commonFr from './fr/common.json';
import commonEs from './es/common.json';
import {DEFAULT_LANGUAGE} from "../../constant";

export const defaultNS = 'common';


const resources = {
  en: {
    common: commonEn,
  },
  fr: {
    common: commonFr,
  },
  es: {
    common: commonEs,
  }
} as const;

i18n.use(initReactI18next).init({
  defaultNS,
  resources,
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});

// const userLocale = navigator.language;
// i18n.changeLanguage(userLocale);

export default i18n;
