import { colors, device, typography } from "constant/styles";
import styled from "styled-components";

const sliderSize = 360;
const sliderSizePhone = 260;

export const Container = styled.div`
    background: ${colors.white.primary};
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 32px;
    gap: 20px;
    margin-left: ${sliderSize / 2}px;

    @media ${device.ipad} {
        margin-left: 0;
        margin-top: ${sliderSize / 2}px;
        flex-direction: column;
    }

    @media ${device.phone} {
        margin-top: ${sliderSizePhone / 2}px;
        padding: 20px 12px;
    }
`;

export const SwiperContainer = styled.div`
    flex-basis: ${sliderSize / 2}px;
    flex-shrink: 0;
    position: relative;

    @media ${device.phone} {
        flex-basis: ${sliderSizePhone / 2}px;
    }

    & > div {
        width: ${sliderSize}px;
        height: ${sliderSize}px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-50%);
        border-radius: 30px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        @media ${device.ipad} {
            right: 0;
            transform: translateY(-50%) translateX(50%);
        }

        @media ${device.phone} {
            width: ${sliderSizePhone}px;
            height: ${sliderSizePhone}px;
        }
    }
`;

export const MainContent = styled.div`
    flex-grow: 1;
`;

export const BtnInquire = styled.button`
    display: block;
    color: #e6e8ec;
    font-family: ${typography.fontFamily};
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    background-color: #29247a;
    margin: 0 auto;

    @media ${device.phone} {
        font-size: 30px;
        padding: 10px 16px;
    }

    &:hover {
        opacity: 0.5;
    }
`;

export const PriceNote = styled.p`
    color: #22252d;
    font-family: ${typography.fontFamily};
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin: 12px 0 0;

    @media ${device.phone} {
        font-size: 11px;
    }
`;

export const AicraftName = styled.h6<{ $long: boolean }>`
    color: #29247a;
    font-family: ${typography.fontFamily};
    font-size: ${({ $long }) => ($long ? "40px" : "64px")};
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin: 0;
    line-height: 96px;

    @media ${device.phone} {
        font-size: 40px;
        line-height: normal;
    }
`;

export const AircraftTypes = styled.p`
    color: #22252d;
    font-family: ${typography.fontFamily};
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 12px 0 0;

    @media ${device.phone} {
        text-align: center;
        font-size: 13px;
    }

    b {
        display: block;
        color: #29247a;
    }
`;

export const AircraftPriceRange = styled.p<{ $long: boolean }>`
    color: #22252d;
    font-family: ${typography.fontFamily};
    font-size: ${({ $long }) => ($long ? "40px" : "52px")};
    font-style: normal;
    text-align: center;
    font-weight: 700;
    line-height: 78px;
    margin: 0 0 24px;

    @media ${device.phone} {
        font-size: 32px;
        line-height: normal;
    }

    span {
        color: #22252d;
        font-size: 12px;
        font-family: ${typography.fontFamily};
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        display: block;
        text-align: center;
        margin-bottom: 0 0 32px;

        @media ${device.phone} {
            font-size: 21px;
        }
    }
`;

export const AircraftParams = styled.div`
    background-color: rgba(#d8d8d8, 0.33);
    flex-basis: 280px;
    flex-shrink: 0;
    padding: 24px;
    border-radius: 30px;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${device.ipad} {
        flex-basis: unset;
    }
`;

export const AircraftParam = styled.p`
    display: flex;
    margin: 0 0 12px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const AircraftParamLabel = styled.span`
    flex-basis: 110px;
    flex-shrink: 0;
    color: #22252d;
    font-family: ${typography.fontFamily};
    align-self: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media ${device.phone} {
        font-size: 16px;
    }
`;

export const AircraftParamIcon = styled.span`
    margin-right: 12px;
    flex-shrink: 0;
    align-self: center;

    @media ${device.ipad} {
        svg {
            width: 44px;
            height: 44px;
        }
    }
`;

export const AircraftParamValue = styled.span`
    border-left: 1px solid #29247a;
    padding-left: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const AircraftParamValueSeats = styled(AircraftParamValue)`
    color: #22252d;
    font-family: ${typography.fontFamily};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

export const AircraftParamValueLegs = styled(AircraftParamValue).attrs({
    as: "ul",
})`
    li {
        display: block;
        color: #22252d;
        font-family: ${typography.fontFamily};
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        white-space: nowrap;

        b {
            color: #29247a;
            font-family: ${typography.fontFamily};
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: block;
        }
    }
`;
