import { IconAngleLeft } from "components/Icons";
import styled from "styled-components";

type Props = JSX.IntrinsicElements["button"] & {
    $direction: "prev" | "next";
};

const Btn = styled.button<Props>`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 3;
    top: 50%;
    background: rgba(255, 255, 255, 0.6);
    transform: translateY(-50%);
    right: ${(props) => (props.$direction === "next" ? "20px;" : "unset")};
    left: ${(props) => (props.$direction === "next" ? "unset" : "20px;")};

    svg {
        transform: ${(props) =>
            props.$direction === "next" ? "rotate(180deg);" : ""};

        path {
            fill: #222529;
        }
    }
`;

const SwiperNavBtn = (props: Props) => {
    return (
        <Btn {...props} type="button">
            <IconAngleLeft />
        </Btn>
    );
};

export default SwiperNavBtn;
