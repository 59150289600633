import classNames from "classnames";
import {IconAngleLeft} from "components/Icons";
import LuggageField from "components/LuggageField";
import PhoneInput from "components/PhoneInput";
import TextField from "components/TextField";
import {Container} from "components/UI";
import BtnBack from "components/UI/BtnBack";
import {colors, device, typography} from "constant/styles";
import {
  Luggage,
  PreferredContactType,
  useInquireMutation,
} from "gql/generted";
import {useFormField} from "helpers";
import {
  FormEvent,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {CountryData} from "react-phone-input-2";
import styled from "styled-components";
import {UseSearchConfig} from "types";
import FlightDetails from "../FlightDetails";
import {useUTMParameters} from "../../../helpers/hooks/useUTMParameters";
import {useTranslation} from "react-i18next";

type Props = Pick<UseSearchConfig, "passengers" | "trips" | "tripType"> & {
  onClickHome(): void;
  onSubmit(dealId: number): void;
};

const Button = styled.button`
    color: #e6e8ec;
    font-family: ${typography.fontFamily};
    font-size: 26.31px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 7px 16px;
    border-radius: 10px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #29247a;
    width: 100%;

    &:hover {
        background: rgba(34, 37, 45, 0.9);
    }

    &:active {
        background: rgba(34, 37, 45, 0.9);
    }
`;

const Form = styled.form`
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(12px) !important;
    padding: 24px 40px;
    position: relative;
    z-index: 1;

    @media ${device.ipad} {
        border-radius: 0;
    }

    @media ${device.phone} {
        padding: 16px;
    }

    .Form {
        &Header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 32px;

            @media ${device.ipad} {
                align-items: center;
                flex-direction: column;
            }

            &Logos {
                flex-shrink: 1;
                display: flex;
                justify-content: center;
                margin-bottom: 12px;

                @media ${device.ipad} {
                    text-align: center;
                    margin-top: 12px;
                }

                img {
                    max-width: 100%;
                }
            }
        }

        &Main {
            max-width: 1000px;
            margin: 0 auto;
                /* display: grid;
            grid-template-columns: 440px 1fr;
            align-items: flex-start;
            gap: 60px;

            @media ${device.ipad} {
                gap: 24px;
                grid-template-columns: 1fr;
            }

            @media ${device.phone} {
                grid-template-columns: 1fr;
            } */
        }
    }

    .Title {
        color: #fafafa;
        font-family: ${typography.fontFamily};
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin: 0;
    }

    .Description {
        color: #fafafa;
        font-family: ${typography.fontFamily};
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        line-height: normal;
        white-space: pre-wrap;
        margin: 0 0 40px;

        @media ${device.phone} {
            font-size: 16px;
            font-weight: 300;
        }
    }

    .ContactMethod {
        border: none;
        padding: 0;
        margin: 0 0 40px;

        legend {
            display: block;
            margin: 0 0 12px;
            color: #fafafa;
            font-family: ${typography.fontFamily};
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &Options {
            display: flex;

            & > * {
                margin-right: 90px;
                cursor: pointer;
                color: #fafafa;
                font-family: ${typography.fontFamily};
                font-size: 17px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;

                &:last-child {
                    margin-right: 0;
                }

                @media ${device.ipad} {
                    margin-right: 6px;
                    font-size: 16px;
                }

                input {
                    margin-left: 12px;

                    @media ${device.ipad} {
                        margin-right: 6px;
                    }
                }
            }
        }
    }

    .MainFields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 16px;
        margin-bottom: 20px;

        @media ${device.phone} {
            grid-template-columns: 1fr;
        }
    }

    .AdditionalFields {
        &Title {
            color: #fafafa;
            font-family: ${typography.fontFamily};
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 0 0 24px;

            &Icon {
                transition: 0.2s;
                transform: rotate(-90deg);

                path {
                    fill: #fafafa;
                }

                &.rotate {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .FuelField {
        color: #fafafa;
        font-family: ${typography.fontFamily};
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0 0 24px;

        input {
            margin: 0 12px 0 0;
            width: 14px;
            height: 14px;
        }
    }

    .FormError {
        font-family: ${typography.fontFamily};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        color: ${colors.red.primary};
    }
`;

const Summary = styled.div`
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(12px) !important;
    margin-top: 40px;
    padding: 24px 40px;

    @media ${device.ipad} {
        border-radius: 0;
    }

    @media ${device.phone} {
        padding: 16px;
    }
`;


const PersonalDetailsForm = ({
                               onClickHome,
                               onSubmit,
                               passengers,
                               trips,
                               tripType,
                             }: Props) => {
  const randomId = useMemo(() => Math.random().toString(), []);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({behavior: "smooth"});
  }, []);
  const [t] = useTranslation("common");
  const utmParameters = useUTMParameters();
  const [inquireMutation, {loading}] = useInquireMutation();
  const [formError, setFormError] = useState("");
  const [isOpenAdditionalFields, setIsOpenAdditionalFields] = useState(false);
  const [openToFuel, setOpenToFuel] = useState(false);
  const firstNameField = useFormField("");
  const lastNameField = useFormField("");
  const emailField = useFormField("");
  const phoneField = useFormField({value: "", config: {} as CountryData});
  const petsField = useFormField("");
  const commentsField = useFormField("");
  const contactMethod = useFormField(PreferredContactType.Call);
  const luggageField = useFormField<Luggage>({
    carryOn: 0,
    handLuggage: 0,
    skis: 0,
    golfBags: 0,
    other: "",
  });

  const [formTitle, formDescription, formRequiredField] = useMemo(() => [
    t("personalDetails"), t("fillInformation"), t("requiredField")
  ], [t]);


  const submit = (e: FormEvent) => {
    e.preventDefault();
    let hasError = false;

    if (!firstNameField.value) {
      hasError = true;
      firstNameField.changeError(formRequiredField);
    }
    if (!lastNameField.value) {
      hasError = true;
      lastNameField.changeError(formRequiredField);
    }
    if (!emailField.value) {
      hasError = true;
      emailField.changeError(formRequiredField);
    }

    if (
      !phoneField.value.value ||
      (phoneField.value.config &&
        phoneField.value.config.format.replace(/[^.]/g, "").length !==
        phoneField.value.value.toString().length)
    ) {
      hasError = true;
      phoneField.changeError(formRequiredField);
    }

    if (hasError) {
      return;
    }

    inquireMutation({
      variables: {
        inquireInput: {
          ...utmParameters,
          passengers,
          trips: trips
            .map((trip) => {
              const routes = [
                {
                  route: {
                    airportFrom:
                      trip.destinationValue.from?.id!,
                    airportTo:
                      trip.destinationValue.to?.id!,
                  },
                  dateTimeValue: {
                    departDateTime: {
                      time: trip.dateTimeValue
                        .departDateTime.time,
                      date: trip.dateTimeValue.departDateTime.date?.toISODate(),
                    },
                  },
                },
              ];

              if (
                trip.dateTimeValue.returnDateTime.date &&
                trips.length
              ) {
                routes.push({
                  route: {
                    airportTo:
                      trip.destinationValue.from?.id!,
                    airportFrom:
                      trip.destinationValue.to?.id!,
                  },
                  dateTimeValue: {
                    departDateTime: {
                      time: trip.dateTimeValue
                        .returnDateTime.time,
                      date: trip.dateTimeValue.returnDateTime.date?.toISODate(),
                    },
                  },
                });
              }

              return routes;
            })
            .flat(),
          comments: commentsField.value,
          aircraftId: null,
          email: emailField.value,
          firstName: firstNameField.value,
          lastName: lastNameField.value,
          luggage: luggageField.value,
          pets: petsField.value,
          phone: phoneField.value.value,
          isFuelStopOk: openToFuel,
          preferredContactType: contactMethod.value,
        },
      },
    })
      .then(({data}) => {
        onSubmit(data?.inquire.inquireId!);
      })
      .catch((e) => {
        setFormError(
          e?.networkError?.result?.errors?.[0]?.message ||
          e?.graphQLErrors?.[0]?.message ||
          e?.message
        );
      });
  };

  return (
    <Container ref={ref}>
      <Form onSubmit={submit}>
        <div className={classNames("FormHeader")}>
          <BtnBack
            className={classNames("FormHeaderBtnBack")}
            onClick={onClickHome}
          >
            {t('editRequest')}
          </BtnBack>
        </div>

        <div className={classNames("FormMain")}>
          <div className={classNames("Fields")}>
            <div className={classNames("FormHeaderLogos")}>
              <img
                src={require("assets/images/logos.png")}
                alt=""
              />
            </div>
            <h2 className={classNames("Title")}>{formTitle}</h2>
            <p className={classNames("Description")}>
              {formDescription}
            </p>

            <div className={classNames("MainFields")}>
              <TextField
                label={`${t('firstName')} *`}
                placeholder={t('firstName')}
                error={firstNameField.error}
                value={firstNameField.value}
                onChange={(e) =>
                  firstNameField.change(e.target.value)
                }
              />
              <TextField
                label={`${t('lastName')} *`}
                placeholder={t('lastName')}
                error={lastNameField.error}
                value={lastNameField.value}
                onChange={(e) =>
                  lastNameField.change(e.target.value)
                }
              />
              <TextField
                type="email"
                label={`${t('email')} *`}
                placeholder={t('email')}
                error={emailField.error}
                value={emailField.value}
                onChange={(e) =>
                  emailField.change(e.target.value)
                }
              />
              <PhoneInput
                label={`${t('phone')} *`}
                error={phoneField.error}
                value={phoneField.value.value}
                onChange={(value, config) => {
                  phoneField.change({value, config});
                }}
              />
            </div>
            <fieldset className={classNames("ContactMethod")}>
              <legend>{t('preferedContact')}</legend>

              <div className={classNames("ContactMethodOptions")}>
                {(
                  [
                    [PreferredContactType.Call, t("call")],
                    [PreferredContactType.Email, t('email')],
                    [PreferredContactType.Text, t("text")],
                    [
                      PreferredContactType.Whatsapp,
                      "Whatsapp",
                    ],
                  ] as const
                ).map(([method, label]) => {
                  const inputId = `contact-${method}-${randomId}`;
                  return (
                    <label key={method} htmlFor={inputId}>
                      {label}
                      <input
                        type="radio"
                        id={inputId}
                        value={method}
                        checked={
                          method ===
                          contactMethod.value
                        }
                        onChange={() =>
                          contactMethod.change(method)
                        }
                      />
                    </label>
                  );
                })}
              </div>
            </fieldset>

            <div>
              <h3
                onClick={() =>
                  setIsOpenAdditionalFields(
                    !isOpenAdditionalFields
                  )
                }
                className={classNames("AdditionalFieldsTitle")}
              >
                {t("additionalInformation")}{" "}
                <IconAngleLeft
                  className={classNames(
                    "AdditionalFieldsTitleIcon",
                    {
                      ["rotate"]: isOpenAdditionalFields,
                    }
                  )}
                />
              </h3>

              {isOpenAdditionalFields && (
                <Fragment>
                  <label className={classNames("FuelField")}>
                    <input
                      checked={openToFuel}
                      type="checkbox"
                      onChange={() =>
                        setOpenToFuel(!openToFuel)
                      }
                    />
                    {t('openToFuelStop')}
                  </label>

                  <LuggageField
                    label={t("luggage")}
                    placeholder={t("luggage")}
                    error={luggageField.error}
                    value={luggageField.value}
                    onChange={luggageField.change}
                  />
                  <TextField
                    label={t("pets")}
                    placeholder={t("numberOfPets")}
                    error={petsField.error}
                    value={petsField.value}
                    onChange={(e) =>
                      petsField.change(e.target.value)
                    }
                  />

                  <TextField
                    label={t("additionalComments")}
                    placeholder={t("addAnything")}
                    error={commentsField.error}
                    value={commentsField.value}
                    onChange={(e) =>
                      commentsField.change(e.target.value)
                    }
                  />
                </Fragment>
              )}
            </div>

            {formError && (
              <p className={classNames("FormError")}>
                {formError}
              </p>
            )}

            <Button
              className={classNames("SubmitBtn")}
              disabled={loading}
              type="submit"
            >
              {t('viewResults')}
            </Button>
          </div>
        </div>
      </Form>

      <Summary>
        <FlightDetails
          mayBeWithMap
          onClickEditRequest={onClickHome}
          passengers={passengers}
          trips={trips}
          tripType={tripType}
        />
      </Summary>
    </Container>
  );
};

export default PersonalDetailsForm;
