import styled from "styled-components";

const Label = styled.label`
    text-transform: uppercase;
    transition: 0.2s;
    color: #fafafa;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    width: fit-content;
    margin-bottom: 10px;
`;

export default Label;
