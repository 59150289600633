import {Container} from "components/UI";
import {colors, device, typography} from "constant/styles";
import {AircraftFragment} from "gql/generted";
import {useEffect, useMemo, useRef} from "react";
import styled from "styled-components";
import {UseSearchConfig} from "types";
import FlightDetails from "../FlightDetails";
import AircraftCard from "./AircraftCard";
import {useTranslation} from "react-i18next";

type Props = Pick<
  UseSearchConfig,
  "passengers" | "trips" | "tripType" | "currency"
> & {
  onClickInquire(v: AircraftFragment): void;
  aircraftCategories: AircraftFragment[];
  onClickEditRequest(): void;
};

const Component = styled.div`
    padding: 40px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(12px) !important;

    @media ${device.ipad} {
        border-radius: 0;
    }

    @media ${device.phone} {
        padding: 20px;
    }
`;

const CategoriesSection = styled.div`
    padding-top: 40px;
    border-top: 1px solid #e6e8ec;
`;
const Title = styled.h3`
    margin: 0 0 4px;
    text-align: center;
    font-size: 48px;
    font-family: ${typography.fontFamily};
    font-size: 48px;
    line-height: normal;
    font-weight: 700;
    color: ${colors.white.primary};

    @media ${device.phone} {
        font-size: 32px;
    }
`;
const Description = styled.p`
    text-align: center;
    margin: 0 0 32px;
    font-family: ${typography.fontFamily};

    font-size: 20px;
    font-weight: 300;
    white-space: pre-wrap;
    color: ${colors.white.primary};

    @media ${device.phone} {
        font-size: 16px;
    }
`;

const AircraftList = styled.div`
    & > *:not(:last-child) {
        margin-bottom: 72px;
    }
`;

const AircractCategories = ({
                              onClickInquire,
                              onClickEditRequest,
                              aircraftCategories,
                              tripType,
                              trips,
                              passengers,
                              currency,
                            }: Props) => {
  const [t] = useTranslation('common')
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({behavior: "smooth"});
  }, []);

  const [title, description] = useMemo(() => [t('aircraftTitle'), t('aircraftDescription')], [t]);

  return (
    <Container ref={ref}>
      <Component>
        <FlightDetails
          mayBeWithMap={false}
          onClickEditRequest={onClickEditRequest}
          trips={trips}
          passengers={passengers}
          tripType={tripType}
        />
        <CategoriesSection>
          <Title>{title}</Title>
          <Description>{description}</Description>

          <AircraftList>
            {aircraftCategories.map((data) => (
              <AircraftCard
                currency={currency!}
                tripType={tripType}
                data={data}
                key={data.aircraftType}
                onClickInquire={() => onClickInquire(data)}
              />
            ))}
          </AircraftList>
        </CategoriesSection>
      </Component>
    </Container>
  );
};

export default AircractCategories;
