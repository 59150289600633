import classNames from "classnames";
import BtnBack from "components/UI/BtnBack";
import DateTimePicker from "components/DateTimeRangePicker";
import DestinationFields from "components/DestinationFields";
import {IconPlane} from "components/Icons";
import PassengersField from "components/PassengersField";
import {TripType, UseSearchConfig} from "types";
import GoogleMap from "./Map";
import styled from "styled-components";
import {colors, device, typography} from "constant/styles";
import {memo} from "react";
import {useTranslation} from "react-i18next";

type Props = Pick<UseSearchConfig, "passengers" | "trips" | "tripType"> & {
  onClickEditRequest(): void;
  mayBeWithMap?: boolean;
};

const Container = styled.div`
    &.withMap {
        display: grid;
        gap: 130px;
        grid-template-columns: 1fr 1fr;

        @media ${device.ipad} {
            gap: 0px;
            grid-template-columns: 1fr;
        }
    }
`;

// TODO: Divide this styles for styled-components
const Component = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    @media ${device.ipad} {
        padding-top: 100px;

        &.alwaysTopHeader {
            padding-top: 40px;
        }

        &.withMap {
            padding-top: 0px;
        }
    }

    .BtnBack {
        &:not(.static) {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .HeaderTitle {
        margin: 0 auto 40px;
        display: flex;
        max-width: calc(100% - 400px);
        align-items: center;
        justify-content: space-between;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 60px;
        font-family: ${typography.fontFamily};
        color: ${colors.white.primary};

        &.fullSize {
            max-width: 100%;
        }

        @media ${device.ipad} {
            justify-content: center;
            max-width: unset;
            margin: 16px auto;

            &:not(.alwaysTop) {
                margin: 16px auto 0;
                order: 3;
            }
        }

        @media ${device.phone} {
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 32px;
            font-family: ${typography.fontFamily};
        }

        svg {
            flex-shrink: 0;
            margin: 0 12px;

            @media ${device.ipad} {
                margin: 0;
            }

            @media ${device.phone} {
                flex-shrink: 1;
            }
        }

        &Name {
            word-break: break-word;
        }

        &Dots {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            margin: 0 12px;

            @media ${device.ipad} {
                flex-grow: unset;
            }

            span {
                width: 7px;
                height: 7px;
                margin: 0 3px;
                border-radius: 50%;
                background: ${colors.white.primary};
            }
        }
    }

    .PassengersField {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 10px;
        width: 200px;
        height: 54px;

        &.withMap {
            position: static;
            width: 100%;

            @media ${device.ipad} {
                width: 120px;
                position: absolute;
            }
        }

        & > * {
            justify-content: center;
        }

        @media ${device.ipad} {
            width: 120px;
        }

        @media ${device.phone} {
            height: 36px;
        }
    }

    .TripRow {
        display: flex;
        gap: 200px;

        &.column {
            gap: 0;
            flex-direction: column;
        }

        @media ${device.ipad} {
            gap: 0;
            flex-direction: column;
        }

        @media ${device.phone} {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        & > * {
            flex-basis: 50%;

            @media ${device.ipad} {
                flex-basis: unset;
            }
        }
    }

    .DestinationFields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-bottom: 8px;

        @media ${device.phone} {
            margin-bottom: 0;
            gap: 0;
            grid-template-columns: 1fr;
        }
    }

    .AiportFieldFrom {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        @media ${device.phone} {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 0;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 0;
        }
    }

    .AiportFieldTo {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        @media ${device.phone} {
            border-radius: 0;
        }
    }

    .DateTimePicker {
        & > * {
            display: grid;
            margin-bottom: 8px;
            grid-template-columns: 1fr 1fr;

            @media ${device.ipad} {
                gap: 10px;
            }

            @media ${device.phone} {
                margin-bottom: 0;
                gap: 0;
                grid-template-columns: 1fr;
            }
        }

        &.departOnly > * {
            grid-template-columns: 1fr;
        }
    }

    .DepartField {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        @media ${device.phone} {
            border-radius: 0;
        }

        &.departOnly {
            border-radius: 10px;

            @media ${device.phone} {
                border-top-right-radius: 0;
                border-bottom-right-radius: 10px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 10px;
            }
        }
    }

    .ReturnField {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        @media ${device.phone} {
            border-top-right-radius: 0;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 10px;
        }
    }

    .TripsTitle {
        color: #fafafa;
        font-family: ${typography.fontFamily};
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-top: 1px solid #e6e8ec;
        padding-top: 20px;
        margin: 0 0 20px;
    }
`;

const MapContainer = styled.div`
    padding-top: 66px;

    @media ${device.ipad} {
        height: 280px;
        padding-top: 0;
    }

    & > * {
        border-radius: 10px;
    }
`;

const FlightDetails = memo(
  ({
     trips,
     passengers,
     onClickEditRequest,
     tripType,
     mayBeWithMap,
   }: Props) => {
    const [t] = useTranslation('common')
    const withMap = mayBeWithMap && tripType !== TripType.MULTI_LEG;
    const fromCityName = trips[0].destinationValue.from?.code;
    const toCityName = trips[trips.length - 1].destinationValue.to?.code;

    const HeaderTitleDots = (
      <span className={classNames("HeaderTitleDots")}>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
            </span>
    );

    return (
      <Container
        className={classNames({
          withMap: withMap,
        })}
      >
        <Component
          className={classNames({
            ["withMap"]: withMap,
            ["alwaysTopHeader"]: mayBeWithMap,
          })}
        >
          <BtnBack
            className={classNames("BtnBack", {
              ["static"]: withMap,
            })}
            onClick={onClickEditRequest}
          >
            {t('editRequest')}
          </BtnBack>

          <h2
            className={classNames("HeaderTitle", {
              ["alwaysTop"]: mayBeWithMap,
              ["fullSize"]: withMap,
            })}
          >
                        <span className={classNames("HeaderTitleName")}>
                            {fromCityName}
                        </span>

            {HeaderTitleDots}

            <IconPlane/>

            {HeaderTitleDots}
            <span className={classNames("HeaderTitleName")}>
                            {toCityName}
                        </span>
          </h2>

          {withMap && (
            <h3 className={classNames("TripsTitle")}>
              {t('flightDetails')}
            </h3>
          )}

          {trips.map((trip, index) => {
            return (
              <div
                className={classNames("TripRow", {
                  ["column"]: withMap,
                })}
                key={index}
              >
                <DestinationFields
                  value={trip.destinationValue}
                  readOnly
                  className={classNames("DestinationFields")}
                  aiportFieldFromClassName={classNames(
                    "AiportFieldFrom"
                  )}
                  aiportFieldToClassName={classNames(
                    "AiportFieldTo"
                  )}
                />
                <DateTimePicker
                  value={trip.dateTimeValue}
                  departOnly={
                    tripType !== TripType.ROUND_TRIP
                  }
                  readOnly
                  className={classNames("DateTimePicker", {
                    ["departOnly"]:
                    tripType !== TripType.ROUND_TRIP,
                  })}
                  departFieldClassName={classNames(
                    "DepartField",
                    {
                      ["departOnly"]:
                      tripType !==
                      TripType.ROUND_TRIP,
                    }
                  )}
                  returnFieldClassName={classNames(
                    "ReturnField"
                  )}
                />
              </div>
            );
          })}

          <PassengersField
            readOnly
            className={classNames("PassengersField", {
              ["withMap"]: withMap,
            })}
            value={passengers}
          />
        </Component>

        {withMap && (
          <MapContainer>
            <GoogleMap trips={trips}/>
          </MapContainer>
        )}
      </Container>
    );
  }
);

export default FlightDetails;
