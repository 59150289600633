import { Wrapper } from "@googlemaps/react-wrapper";
import { AirportFragment } from "gql/generted";
import { useEffect, useRef } from "react";
import { UseSearchConfig } from "types";

type Props = Pick<UseSearchConfig, "trips">;

const MapComponent = ({ trips }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const mapRef = useRef<google.maps.Map>();

    useEffect(() => {
        const points: AirportFragment[] = [];

        trips.forEach(({ destinationValue: { to, from } }) => {
            points.push(from!);
            points.push(to!);
        });

        mapRef.current = new window.google.maps.Map(ref.current!, {
            center: {
                lat: 49.988358,
                lng: 36.232845,
            },
            disableDefaultUI: true,

            zoom: 8,
        });

        const bounds = new window.google.maps.LatLngBounds();

        points.forEach((point, i) => {
            new window.google.maps.Marker({
                position: point,
                map: mapRef.current,
                icon: {
                    anchor: new google.maps.Point(20, 20),
                    url:
                        i === 0
                            ? require("assets/images/marker-start-point.png")
                            : i !== points.length - 1 ||
                              points[0].id !== point.id
                            ? require("assets/images/marker-end-point.png")
                            : "undefined",
                },
            });
            bounds.extend(point);
        });

        mapRef.current.fitBounds(bounds);

        new window.google.maps.Polyline({
            path: points,
            map: mapRef.current,
            strokeOpacity: 0,
            icons: [
                {
                    icon: {
                        path: "M 0,-1 0,1",
                        strokeOpacity: 1,
                        scale: 4,
                        strokeColor: "#4251D8",
                    },
                    offset: "0",
                    repeat: "20px",
                },
            ],
        });
    });

    return <div style={{ height: "100%" }} ref={ref} id="map" />;
};

const GoogleMap = (props: Props) => (
    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}>
        <MapComponent {...props} />
    </Wrapper>
);

export default GoogleMap;
