import { IconMinus, IconPlus } from "components/Icons";
import { colors, typography } from "constant/styles";
import { ReactNode } from "react";
import styled from "styled-components";

type DropdownCountItemProps = {
    label: ReactNode;
    description: ReactNode;
    value: number;
    onChange?: (v: number) => void;
    min: number;
    max: number;
};

const Component = styled.div`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid ${colors.grey.secondary};
    }
`;

const LabelBlock = styled.div`
    flex-grow: 1;
`;

const Label = styled.p`
    margin: 0 0 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    color: ${colors.black.primary};
`;

const Description = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    font-family: ${typography.fontFamily};
    color: ${colors.black.secondary};
`;

const Actions = styled.div`
    align-self: center;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 8px;
`;

const ActionBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 1px solid ${colors.grey.dark};
    transition: 0.2s;

    svg {
        flex-shrink: 0;
        path {
            transition: 0.2s;
        }
    }

    &:disabled {
        border-color: ${colors.grey.primary};

        svg path {
            stroke: ${colors.grey.primary};
        }
    }
`;

const Value = styled.span`
    width: 32px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: ${typography.fontFamily};
    color: ${colors.black.primary};
`;

export const DropdownCountItem = ({
    label,
    description,
    value,
    onChange,
    min,
    max,
}: DropdownCountItemProps) => {
    const disabledPlus = value === max;
    const disabledMinus = value === min;

    const increase = () => {
        onChange?.(value + 1);
    };
    const decrease = () => {
        onChange?.(value - 1);
    };

    return (
        <Component>
            <LabelBlock>
                <Label>{label}</Label>
                <Description>{description}</Description>
            </LabelBlock>
            <Actions>
                <ActionBtn
                    onClick={decrease}
                    type="button"
                    disabled={disabledMinus}
                >
                    <IconMinus />
                </ActionBtn>
                <Value>{value}</Value>
                <ActionBtn
                    onClick={increase}
                    type="button"
                    disabled={disabledPlus}
                >
                    <IconPlus />
                </ActionBtn>
            </Actions>
        </Component>
    );
};
