import QuoteResults from "containers/QuoteResults";
import SearchRow from "containers/SearchRow";
import {CurrencyEnum, useGeoipQuery} from "gql/generted";
import {useLanguageParam, useSearchConfig} from "helpers";
import {Fragment, useEffect, useState} from "react";
import i18next from "i18next";
import {getLanguage} from "./helpers/locales";
import ReactGA from "react-ga4";

ReactGA.initialize('G-Q8YRBVHXDF')

const Widget = () => {
  const [showResults, setShowResults] = useState(false);
  const searchConfig = useSearchConfig();
  const lang = useLanguageParam()
  const {currency, setCurrency} = searchConfig;
  const geoipQuery = useGeoipQuery();
  const geoip = geoipQuery.data?.geoip;
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Widjet Israjets" });
  }, []);

  const onSubmitSearch = () => {
    setShowResults(true);
  };

  const onClickEditRequest = () => {
    setShowResults(false);
  };

  useEffect(() => {
    if (!currency && geoip) {
      setCurrency(geoip.currency ?? CurrencyEnum.Usd);
    }

  }, [geoip, currency, setCurrency]);

  useEffect(() => {
    if (lang && lang !== i18next.language) {
      const supportedLang = getLanguage(lang);
      i18next.changeLanguage(supportedLang);
    }
  }, [lang]);
  return (
    <Fragment>
      {!showResults && (
        <SearchRow {...searchConfig} onClickSubmit={onSubmitSearch}/>
      )}

      {showResults && (
        <QuoteResults
          {...searchConfig}
          onClickEditRequest={onClickEditRequest}
        />
      )}
    </Fragment>
  );
};

export default Widget;
