import classNames from "classnames";
import { DropdownCountItem } from "components/DropdownCountItem";
import { IconAngleLeft } from "components/Icons";
import TextField from "components/TextField";
import { DropdownContent } from "components/UI";
import Label from "components/UI/Label";
import { BREAKPOINT_DESKTOP, BREAKPOINT_IPAD } from "constant";
import { colors, typography } from "constant/styles";
import { Luggage } from "gql/generted";
import { useDropdown, useWidthCondition } from "helpers";
import update from "immutability-helper";
import { useMemo } from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

type Props = Omit<JSX.IntrinsicElements["input"], "value" | "onChange"> & {
    label: string;
    componentClassName?: string;
    error?: string;
    value: Luggage;
    onChange(v: Luggage): void;
};

const Component = styled.div`
    position: relative;
    margin-bottom: 16px;

    .OtherField {
        &Label {
            font-size: 16px;
            font-family: ${typography.fontFamily};
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: ${colors.black.primary};
            text-transform: unset;
        }

        &Component {
            margin-bottom: 0;
        }

        input {
            border-color: ${colors.grey.secondary};
        }
    }
`;

const InputWrapper = styled.div`
    background: ${colors.white.primary};
    border-radius: 8px;
    position: relative;
`;

const Input = styled.input`
    position: relative;
    z-index: 1;
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: transparent;
    width: 100%;
    height: 54px;
    cursor: pointer;
    transition: 0.2s border-color;

    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: ${typography.fontFamily};
    text-align: left;
    padding: 19px 16px;
    color: #22252d;

    &.focused {
        border-color: ${colors.black.primary};
    }

    &::placeholder {
        color: ${colors.grey.dark};
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: ${typography.fontFamily};
    }
`;

const Icon = styled(IconAngleLeft)<{ $rotate: boolean }>`
    position: absolute;
    transition: 0.2s;
    top: 50%;
    right: 14px;
    transform: translateY(-50%)
        ${({ $rotate }) => ($rotate ? "rotate(90deg)" : "rotate(-90deg)")};
`;

const Content = styled(DropdownContent)`
    width: 327px;
`;

const ContentWrapper = styled.div`
    padding: 13px 16px;
`;

const LuggageField = ({
    className,
    label,
    componentClassName,
    value,
    onChange,
    error,
    ...inputProps
}: Props) => {
    const isIpad = useWidthCondition((w) => w < BREAKPOINT_DESKTOP);
    const isPhone = useWidthCondition((w) => w < BREAKPOINT_IPAD);
const [t] = useTranslation('common')
    const dropdown = useDropdown({
        popperOffsetY: isPhone ? 0 : isIpad ? -15 : undefined,
        popperOptions: {
            placement: isPhone ? "bottom" : "bottom-end",
        },
    });

    const id = useMemo(
        () => inputProps.id || `text-field-${Math.random()}`,
        [inputProps.id]
    );

    const valueAsText = useMemo(() => {
        const valuesWithNumberAsText = [
            {
                label: t("carryOn"),
                value: value.carryOn,
            },
            {
                label: t("handLuggage"),
                value: value.handLuggage,
            },
            {
                label: t("skis"),
                value: value.skis,
            },
            {
                label: t("golfBag"),
                value: value.golfBags,
            },
        ]
            .filter(({ value }) => value)
            .map((item) => `${item.label} x ${item.value}`);

        return (
            [...valuesWithNumberAsText, value.other]
                .filter(Boolean)
                .join(", ") || ""
        );
    }, [t, value]);

    return (
        <Component ref={dropdown.setRef} className={className}>
            <Label onClick={() => dropdown.toggle()} htmlFor={id}>
                {label}
            </Label>
            <InputWrapper>
                <Input
                    {...inputProps}
                    readOnly
                    onClick={() => dropdown.toggle()}
                    id={id}
                    value={valueAsText}
                    className={classNames({
                        focused: dropdown.open,
                    })}
                />

                <Icon $rotate={dropdown.open} />
            </InputWrapper>

            <Content
                ref={dropdown.setPopperRef}
                style={dropdown.popper.styles.popper}
                {...dropdown.popper.attributes.popper}
                data-open={dropdown.open || undefined}
            >
                <ContentWrapper>
                    <DropdownCountItem
                        label={t("carryOn")}
                        description="22cm x 35 cm x 56 cm"
                        min={0}
                        max={10}
                        value={value.carryOn!}
                        onChange={(newValue) =>
                            onChange(
                                update(value, {
                                    carryOn: () => newValue,
                                })
                            )
                        }
                    />
                    <DropdownCountItem
                        label={t("handLuggage")}
                        description="63cm x 36 cm x 21 cm"
                        min={0}
                        max={10}
                        value={value.handLuggage!}
                        onChange={(newValue) =>
                            onChange(
                                update(value, {
                                    handLuggage: () => newValue,
                                })
                            )
                        }
                    />
                    <DropdownCountItem
                        label={t("skis")}
                        description="127cm x 30 cm x 25 cm"
                        min={0}
                        max={10}
                        value={value.skis!}
                        onChange={(newValue) =>
                            onChange(
                                update(value, {
                                    skis: () => newValue,
                                })
                            )
                        }
                    />
                    <DropdownCountItem
                        label={t("golfBag")}
                        description="203cm x 25 cm x 25 cm"
                        min={0}
                        max={10}
                        value={value.golfBags!}
                        onChange={(newValue) =>
                            onChange(
                                update(value, {
                                    golfBags: () => newValue,
                                })
                            )
                        }
                    />

                    <TextField
                        label={t("other")}
                        placeholder={t("otherLuggage")}
                        labelClassName={classNames("OtherFieldLabel")}
                        className={classNames("OtherField")}
                        componentClassName={classNames("OtherFieldComponent")}
                        value={value.other!}
                        onChange={(e) =>
                            onChange(
                                update(value, {
                                    other: () => e.target.value,
                                })
                            )
                        }
                    />
                </ContentWrapper>
            </Content>
        </Component>
    );
};

export default LuggageField;
