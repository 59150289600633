import {InquireMutationVariables} from "../../gql/generted";

export function useUTMParameters() {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = {
    utmMedium: urlParams.get('utm_medium') || null,
    utmCampaign: urlParams.get('utm_campaign') || null,
    utmTerm: urlParams.get('utm_term') || null,
    utmSource: urlParams.get('utm_source') || null,
    utmAdContent: urlParams.get('utm_content') || null,
  } as Pick<InquireMutationVariables['inquireInput'],
    'utmTerm' | 'utmMedium' | 'utmCampaign' | 'utmSource' | 'utmAdContent'>;
  return utmParams;
}
