import { TextFieldContainer } from "components/UI";
import Label from "components/UI/Label";
import { colors, typography } from "constant/styles";
import { FocusEventHandler, useMemo, useState } from "react";
import styled from "styled-components";

type Props = JSX.IntrinsicElements["input"] & {
    label: string;
    labelClassName?: string;
    componentClassName?: string;
    error?: string;
};

const InputWrapper = styled.div`
    position: relative;
`;

const Input = styled.input<{ $focused?: boolean; $error?: boolean }>`
    position: relative;
    z-index: 1;
    display: inline-block;
    border: 1px solid
        ${({ $focused, $error }) =>
            $focused
                ? colors.black.primary
                : $error
                ? colors.red.primary
                : "transparent"};
    border-radius: 8px;
    background: ${colors.white.primary};
    width: 100%;
    height: 54px;
    cursor: pointer;
    text-align: left;
    padding: 19px 16px;
    transition: 0.2s border-color;
    color: #22252d;
    font-family: ${typography.fontFamily};
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &::placeholder {
        color: ${colors.grey.dark};
        font-family: ${typography.fontFamily};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

const TextField = ({
    onFocus,
    onBlur,
    error,
    className,
    label,
    labelClassName,
    componentClassName,
    ...inputProps
}: Props) => {
    const id = useMemo(
        () => inputProps.id || `text-field-${Math.random()}`,
        [inputProps.id]
    );
    const [focused, setFocused] = useState(false);
    const hasError = Boolean(error);

    const onFocusInput: FocusEventHandler<HTMLInputElement> = (e) => {
        setFocused(true);
        onFocus?.(e);
    };

    const onBlurInput: FocusEventHandler<HTMLInputElement> = (e) => {
        setFocused(false);
        onBlur?.(e);
    };

    return (
        <TextFieldContainer className={className}>
            <Label htmlFor={id} className={labelClassName}>
                {label}
            </Label>
            <InputWrapper>
                <Input
                    {...inputProps}
                    id={id}
                    onFocus={onFocusInput}
                    onBlur={onBlurInput}
                    $focused={focused}
                    $error={hasError}
                />
            </InputWrapper>
        </TextFieldContainer>
    );
};

export default TextField;
