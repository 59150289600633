import styled from "styled-components";

const SwiperSlideImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: contain;
    background: #fff;
    border-radius: 20px;
`;

export default SwiperSlideImage;
